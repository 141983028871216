import { Pane, Heading, Text } from 'evergreen-ui';
import { Link } from 'react-router-dom';



const SmartContract = () => {
  return (
    <>
      {
        <Pane
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          marginTop={30}
        >
          <Heading
            textAlign="center"
            fontSize={36}
            marginTop={40}
            id="smartcontract"
            className="h2"
          >
            Smart contract 📃
          </Heading>
          <Link
            className="smartcontract"
            to={{
              pathname:
                'https://explorer.multiversx.com/accounts/erd1qqqqqqqqqqqqqpgqul8643sr78422edx9kxpfdk5qpqw744wlg2sghanht',
            }}
            target="_blank"
          >
            erd1qqqqqqqqqqqqqpgqul8643sr78422edx9kxpfdk5qpqw744wlg2sghanht
          </Link>
        </Pane>
      }
    </>
  );
};

export default SmartContract;
