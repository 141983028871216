import { Ui } from '@elrondnetwork/dapp-utils';
import {
  Pane,
  Heading,
  Paragraph,
  Link,
  Text,
  Card,
  Spinner,
  Image,
  Button
} from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';
import * as Dapp from '@elrondnetwork/dapp';
import { getTokenBalance, getNfts, getVIPNfts, getNft, getScValueQuery } from '../../apiEndpoints';
import { useEffect, useRef, useState } from 'react';
import { stakingTokenIdentifier, NFTCollection, VIPNFTCollection, ranks, denominator } from '../../config';
import InputNumber from 'rsuite/InputNumber';
import useNewTransaction from '../useNewTransaction';
import { RawTransactionType } from '../../helpers/types';
import { NFTStakeContract, MooStakeContract } from '../../config';
import { Toggle, InputPicker, Drawer, ButtonToolbar, IconButton, Placeholder } from 'rsuite';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import {Address} from '@elrondnetwork/erdjs';
import {backData, backgroundData, headData, clothesData, scarsData, eyeFlapData, hatData, mouthData, earringData, hornsData, glassesData} from '../../traitsData';

const AccountTab = () => {
  const { account, address, explorerAddress } = Dapp.useContext();

  const [stakedNfts, setStakedNfts] = useState([]);
  const [stakedBlessedNfts, setStakedBlessedNfts] = useState([]);
  const [stakedDamnedNfts, setStakedDamnedNfts] = useState([]);
  const [stakedEliteNfts, setStakedEliteNfts] = useState([]);
  const [stakedSpecialNfts, setStakedSpecialNfts] = useState([]);

  const [stakingEnabled, setStakingEnabled] = useState(false);
  const [stakingLimit, setStakingLimit] = useState(null);
  const [stakedCount, setStakedCount] = useState(null);
  const [nfts, setNftsList] = useState([]);
  const [vipNfts, setVIPNftsList] = useState([]);
  const [blessedNftsNonces, setBlessedNftsNoncesList] = useState([]);
  const [damnedNftsNonces, setDamnedNftsNoncesList] = useState([]);
  const [eliteNftsNonces, setEliteNftsNoncesList] = useState([]);
  const [specialNftsNonces, setSpecialNftsNoncesList] = useState([]);
  const [pending, setPending] = useState(false);
  const [value, setValue] = useState(null);

    const [tokenBalance, setTokenBalance] = useState(null);
    const [stakedAmount, setStakedAmount] = useState(null);
        const [mooStakingRewards, setMooStakingRewards] = useState(null);

    const numberFormatter = new Intl.NumberFormat();

  const [displayAttributesChecked, setDisplayAttributesChecked] = useState(false);

    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();

    const [backSearchValue, setBackSearchValue] = useState(null);
    const [backgroundSearchValue, setBackgroundSearchValue] = useState(null);
    const [clothesSearchValue, setClothesSearchValue] = useState(null);
    const [earringSearchValue, setEarringSearchValue] = useState(null);
    const [eyeFlapSearchValue, setEyeFlapSearchValue] = useState(null);
    const [glassesSearchValue, setGlassesSearchValue] = useState(null);
    const [hatSearchValue, setHatSearchValue] = useState(null);
    const [headSearchValue, setHeadSearchValue] = useState(null);
    const [hornsSearchValue, setHornsSearchValue] = useState(null);
    const [mouthSearchValue, setMouthSearchValue] = useState(null);
    const [scarsSearchValue, setScarsSearchValue] = useState(null);

  const [stakingRewards, setStakingRewards] = useState(null);
  const mounted = useRef(true);
  const smallRes = useMediaQuery({
    query: '(max-width: 600px)',
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setPending(true);

    const fetchTokenBalance = async () => {
          fetch(getTokenBalance(address, stakingTokenIdentifier), {signal})
            .then(response => response.json())
            .then(json => setTokenBalance((json[0].balance / denominator).toFixed(0)));
        };

      const fetchStakedAmount = async () => {
          const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: MooStakeContract, funcName: 'getStakedToken', args: [new Address(address).hex()]})};

          fetch(getScValueQuery(), fetchSettings)
            .then(response => response.json())
            .then(json => setStakedAmount( (parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16) / denominator).toFixed(0)  ));
        };

     const fetchMooStakingRewards = async () => {
          const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: MooStakeContract, funcName: 'getStakingRewards', caller: address})};

          fetch(getScValueQuery(), fetchSettings)
            .then(response => response.json())
            .then(json => setMooStakingRewards( (parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16) / denominator).toFixed(0) ));
        };


    const fetchStakedNfts = async () => {
           const fetchStakedNftsSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getStandardStakedNfts', args: [new Address(address).hex()]})};

          const fetchedNftsResponse = await fetch(getScValueQuery(), fetchStakedNftsSettings);
          const fetchedNftsResponseData = await fetchedNftsResponse.json();
          const identifiersHex = fetchedNftsResponseData.data.data.returnData.map(item => Buffer.from(item,'base64').toString('hex'), 16);
          setStakedNfts(identifiersHex);
        };

    const fetchStakedEliteNfts = async () => {
       const fetchStakedNftsSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getStakedEliteNfts', args: [new Address(address).hex()]})};

      const fetchedNftsResponse = await fetch(getScValueQuery(), fetchStakedNftsSettings);
      const fetchedNftsResponseData = await fetchedNftsResponse.json();
      const identifiersHex = fetchedNftsResponseData.data.data.returnData.map(item => Buffer.from(item,'base64').toString('hex'), 16);
     setStakedEliteNfts(identifiersHex);
    };

    const fetchStakedBlessedNfts = async () => {
       const fetchStakedNftsSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getStakedBlessedNfts', args: [new Address(address).hex()]})};

      const fetchedNftsResponse = await fetch(getScValueQuery(), fetchStakedNftsSettings);
      const fetchedNftsResponseData = await fetchedNftsResponse.json();
      const identifiersHex = fetchedNftsResponseData.data.data.returnData.map(item => Buffer.from(item,'base64').toString('hex'), 16);
     setStakedBlessedNfts(identifiersHex);
    };

    const fetchStakedDamnedNfts = async () => {
       const fetchStakedNftsSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getStakedDamnedNfts', args: [new Address(address).hex()]})};

      const fetchedNftsResponse = await fetch(getScValueQuery(), fetchStakedNftsSettings);
      const fetchedNftsResponseData = await fetchedNftsResponse.json();
      const identifiersHex = fetchedNftsResponseData.data.data.returnData.map(item => Buffer.from(item,'base64').toString('hex'), 16);
     setStakedDamnedNfts(identifiersHex);
    };

        const fetchStakedSpecialNfts = async () => {
           const fetchStakedNftsSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getStakedSpecialNfts', args: [new Address(address).hex()]})};

          const fetchedNftsResponse = await fetch(getScValueQuery(), fetchStakedNftsSettings);
          const fetchedNftsResponseData = await fetchedNftsResponse.json();
          const identifiersHex = fetchedNftsResponseData.data.data.returnData.map(item => Buffer.from(item,'base64').toString('hex'), 16);
          setStakedSpecialNfts(identifiersHex);
        };


    const fetchStakingRewards = async () => {
      const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getStakingRewards', caller: address})};

      fetch(getScValueQuery(), fetchSettings)
        .then(response => response.json())
        .then(json => setStakingRewards(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)));
    };

    const fetchStakingAllowed = async () => {
      const settings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'isAllowedStaker', caller: address})};
      fetch(getScValueQuery(), settings)
        .then(response => response.json())
        .then(json => setStakingEnabled(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16) === 1));
    };

    const fetchStakingLimit = async () => {
      const settings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getStakeLimit'})};
      fetch(getScValueQuery(), settings)
        .then(response => response.json())
        .then(json => setStakingLimit(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)));
    };

    const fetchStakingCount = async () => {
      const settings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getStakedCount'})};
      fetch(getScValueQuery(), settings)
        .then(response => response.json())
        .then(json => setStakedCount(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)));
    };

    const fetchBlessedNftsNonces = async () => {
       const settings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getBlessedPoolNonces'})};

      const response = await fetch(getScValueQuery(), settings);
      const responseData = await response.json();
      const blessedNftsNonces = responseData.data.data.returnData.map(item => Buffer.from(item,'base64').toString('hex'), 16);
      setBlessedNftsNoncesList(blessedNftsNonces);
    };

    const fetchDamnedNftsNonces = async () => {
       const settings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getDamnedPoolNonces'})};

      const response = await fetch(getScValueQuery(), settings);
      const responseData = await response.json();
      const damnedNftsNonces = responseData.data.data.returnData.map(item => Buffer.from(item,'base64').toString('hex'), 16);
      setDamnedNftsNoncesList(damnedNftsNonces);
    };

    const fetchEliteNftsNonces = async () => {
       const settings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getElitePoolNonces'})};

      const response = await fetch(getScValueQuery(), settings);
      const responseData = await response.json();
      const eliteNftsNonces = responseData.data.data.returnData.map(item => Buffer.from(item,'base64').toString('hex'), 16);
      setEliteNftsNoncesList(eliteNftsNonces);
    };

    const fetchSpecialNftsNonces = async () => {
       const settings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getSpecialPoolNonces'})};

      const response = await fetch(getScValueQuery(), settings);
      const responseData = await response.json();
      const specialNftsNonces = responseData.data.data.returnData.map(item => Buffer.from(item,'base64').toString('hex'), 16);
      setSpecialNftsNoncesList(specialNftsNonces);
    };

    const fetchNfts = async () => {
      const response = await fetch(getNfts(address), { signal });
      let data = await response.json();
      data = filterNfts(data);

      setNftsList(data);
      setPending(false);
    };

    const fetchVIPNfts = async () => {
      const response = await fetch(getVIPNfts(address), { signal });
      const data = await response.json();

      setVIPNftsList(data);
      setPending(false);
    };

    if (address) {
      fetchTokenBalance();
      fetchStakedAmount();
      fetchMooStakingRewards();
      fetchNfts();
      fetchVIPNfts();
      fetchStakingRewards();
      fetchStakedNfts();
      fetchStakedSpecialNfts();
      fetchStakedEliteNfts();
      fetchStakedBlessedNfts();
      fetchStakedDamnedNfts();
      fetchStakingLimit();
      fetchStakingCount();
      fetchStakingAllowed();
      fetchEliteNftsNonces();
      fetchSpecialNftsNonces();
      fetchBlessedNftsNonces();
      fetchDamnedNftsNonces();
    }

    return () => {
      mounted.current = false;
    };
  }, [address, backSearchValue, backgroundSearchValue, clothesSearchValue, earringSearchValue, eyeFlapSearchValue,
        glassesSearchValue, hatSearchValue, headSearchValue, hornsSearchValue, mouthSearchValue, scarsSearchValue]);

    const sendTransaction = Dapp.useSendTransaction();
    const newTransaction = useNewTransaction();
    const stake =
      (transaction: RawTransactionType, nftIdentifier: string) => async (e: React.MouseEvent) => {
        const nftCollectionHex = Buffer.from(NFTCollection).toString('hex');
        const nftNonce = nftIdentifier.split("-").pop();
        const smartContract = new Address(NFTStakeContract).hex();
        const smartContractFunction = Buffer.from('stake').toString('hex');
        transaction.data = `ESDTNFTTransfer@${nftCollectionHex}@${nftNonce}@01@${smartContract}@${smartContractFunction}`;
        e.preventDefault();
        sendTransaction({
          transaction: newTransaction(stakeTransaction),
          callbackRoute: '/dashboard',
        });
      };

    function getStakeButtonText(nftIdentifier) {
      const nftNonce = nftIdentifier.split("-").pop();
      if (specialNftsNonces.some((item) => nftNonce === item)) {
        return "Stake to Special Pool";
      }

      if (eliteNftsNonces.some((item) => nftNonce === item)) {
        return "Stake to MEC Pool";
      }

      if (blessedNftsNonces.some((item) => nftNonce === item)) {
        return "Stake to Blessed Pool";
      }

      if (damnedNftsNonces.some((item) => nftNonce === item)) {
        return "Stake to Damned Pool";
      }

      return "Stake to Mooiar Pool";
    }

    function getStakeButtonIntent(nftIdentifier) {
      const nftNonce = nftIdentifier.split("-").pop();

      if (specialNftsNonces.some((item) => nftNonce === item)) {
        return "success";
      }

      if (eliteNftsNonces.some((item) => nftNonce === item)) {
        return "danger";
      }

      if (blessedNftsNonces.some((item) => nftNonce === item)) {
        return "danger";
      }

      if (damnedNftsNonces.some((item) => nftNonce === item)) {
        return "danger";
      }

      return "none";
    }

  function toggle()  {
      setDisplayAttributesChecked(!displayAttributesChecked);
    }

    const handleOpen = key => {
        setOpen(true);
        setPlacement(key);
      };

  function clearFilters()  {
    setBackgroundSearchValue(null);
    setBackSearchValue(null);
    setClothesSearchValue(null);
    setEarringSearchValue(null);
    setEyeFlapSearchValue(null);
    setGlassesSearchValue(null);
    setHatSearchValue(null);
    setHeadSearchValue(null);
    setHornsSearchValue(null);
    setMouthSearchValue(null);
    setScarsSearchValue(null);
  }

  function getActiveFiltersCount() {
    let count = 0;
    if (backgroundSearchValue != null) {count+=1;}
    if (backSearchValue != null) {count+=1;}
    if (clothesSearchValue != null) {count+=1;}
    if (earringSearchValue != null) {count+=1;}
    if (eyeFlapSearchValue != null) {count+=1;}
    if (glassesSearchValue != null) {count+=1;}
    if (hatSearchValue != null) {count+=1;}
    if (headSearchValue != null) {count+=1;}
    if (hornsSearchValue != null) {count+=1;}
    if (mouthSearchValue != null) {count+=1;}
    if (scarsSearchValue != null) {count+=1;}
    return count;
  }

  function filterNfts(data) {
    data = filterByTrait(data, 'Back', backSearchValue);
    data = filterByTrait(data, 'Background', backgroundSearchValue);
    data = filterByTrait(data, 'Clothes', clothesSearchValue);
    data = filterByTrait(data, 'Earring', earringSearchValue);
    data = filterByTrait(data, 'Eye flap', eyeFlapSearchValue);
    data = filterByTrait(data, 'Glasses', glassesSearchValue);
    data = filterByTrait(data, 'Hat', hatSearchValue);
    data = filterByTrait(data, 'Head', headSearchValue);
    data = filterByTrait(data, 'Horns', hornsSearchValue);
    data = filterByTrait(data, 'Mouth', mouthSearchValue);
    data = filterByTrait(data, 'Scars', scarsSearchValue);

    return data;
  }

  function filterByTrait(data, traitType, traitValue){
    if (traitValue != null) {
      data = data.map(nft => ({...nft, attributes: nft.metadata.attributes.filter(attribute => attribute.trait_type === traitType && attribute.value === traitValue)})).filter(item => item.attributes.length > 0);
      console.log("result by "+traitType+" size: " + data.length);
    }

    return data;
  }

  function isFilterEnabled() {
    return backSearchValue!=null || backgroundSearchValue!=null || clothesSearchValue!=null || earringSearchValue!=null ||
      eyeFlapSearchValue!=null || glassesSearchValue!=null || hatSearchValue!=null || headSearchValue!=null ||
      hornsSearchValue!=null || mouthSearchValue!=null || scarsSearchValue!=null;
  }

  const stakeTransaction: RawTransactionType = {
      receiver: address,
      value: '0',
      data: 'ESDTNFTTransfer',
      gasLimit: 80000000,
    };

  return (
    <>
    <Pane marginBottom={10} marginTop={10}>
       <Heading size={700} className="h2">
          Your portfolio
       </Heading>
    </Pane>
    <Pane display="flex" flexDirection={smallRes ? 'column' : 'row'}>
    <Card
        width={smallRes ? '100%' : '50%'}
        marginRight={smallRes ? 0 : 10}
        marginBottom={25}
        padding={30}
        elevation={1}
        className="card"
        >
        <Paragraph data-testid="accountTab-address">
           <Text fontSize={18} className="text">
              Your wallet address:
           </Text>
           <br/>
           <Link href={`${explorerAddress}accounts/${address}`}>
           <Text wordWrap="break-word" className="text">
              {address}
           </Text>
           </Link>
        </Paragraph>
        <br/>
        <Paragraph data-testid="accountTab-address">
           <Text fontSize={18} className="text" textDecoration="underline">
              Balance
           </Text>
           <br/>
           <Text wordWrap="break-word" className="text">
              &nbsp;&nbsp;&nbsp;
              <Ui.Denominate value={account.balance} erdLabel="$EGLD" />
           </Text>
           <br/>
           <Text wordWrap="break-word" className="text">
              &nbsp;&nbsp;&nbsp;{numberFormatter.format(tokenBalance)} $MOO
           </Text>
        </Paragraph>
        </Card>

    <Card
    width={smallRes ? '100%' : '50%'}
    marginLeft={smallRes ? 0 : 10}
    marginBottom={25}
    padding={30}
    elevation={1}
    className="card"
    >
    <Heading size={700} marginBottom={10} className="h2">
       $MOO farm
    </Heading>
        <Heading className="text" marginTop={15}>
           Staking:  {!isNaN(stakedAmount) && numberFormatter.format(stakedAmount - 1)}{isNaN(stakedAmount) && 0} $MOO
        </Heading>
                <Heading className="text" marginTop={15}>
                   Claimable rewards: {!isNaN(mooStakingRewards) && numberFormatter.format(mooStakingRewards)}{isNaN(mooStakingRewards) && 0} $MOO
                </Heading>
    </Card>
    </Pane>
    <Pane marginBottom={10} marginTop={10}>
       <Heading size={700} className="h2">
          Your staking pools
       </Heading>
    </Pane>
    <Pane display="flex" flexDirection={smallRes ? 'column' : 'row'}>
    <Card
    width={smallRes ? '100%' : '10%'}
    marginTop={5}
    marginBottom={10}
    paddingTop={5}
    paddingBottom={20}
    elevation={1}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Heading className="text" marginTop={15}>
       Mooiar
    </Heading>
    <br/>
    <Pane>
       <Text fontSize={28} className="text">
          {stakedNfts.length}
       </Text>
    </Pane>
    </Card>
    <Card
    width={smallRes ? '100%' : '10%'}
    marginTop={5}
    marginBottom={10}
    paddingTop={5}
    paddingBottom={20}
    elevation={1}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Heading className="text" marginTop={15}>
       Special
    </Heading>
    <br/>
    <Pane>
       <Text fontSize={28} className="text">
          {stakedSpecialNfts.length}
       </Text>
    </Pane>
    </Card>
    <Card
    width={smallRes ? '100%' : '10%'}
    marginTop={5}
    marginBottom={10}
    paddingTop={5}
    paddingBottom={20}
    elevation={1}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Heading className="text" marginTop={15}>
       MEC
    </Heading>
    <br/>
    <Pane>
       <Text fontSize={28} className="text">
          {stakedEliteNfts.length}
       </Text>
    </Pane>
    </Card>

    <Card
    width={smallRes ? '100%' : '10%'}
    marginTop={5}
    marginBottom={10}
    paddingTop={5}
    paddingBottom={20}
    elevation={1}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Heading className="text" marginTop={15}>
       Blessed
    </Heading>
    <br/>
    <Pane>
       <Text fontSize={28} className="text">
          {stakedBlessedNfts.length}
       </Text>
    </Pane>
    </Card>

    <Card
    width={smallRes ? '100%' : '10%'}
    marginTop={5}
    marginBottom={10}
    paddingTop={5}
    paddingBottom={20}
    elevation={1}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Heading className="text" marginTop={15}>
       Damned
    </Heading>
    <br/>
    <Pane>
       <Text fontSize={28} className="text">
          {stakedDamnedNfts.length}
       </Text>
    </Pane>
    </Card>

    <Card
    width={smallRes ? '100%' : '50%'}
    marginLeft={smallRes ? 0 : 10}
    marginBottom={10}
    padding={30}
    elevation={1}
    marginTop={5}
    paddingTop={5}
    paddingBottom={20}
    className="card"
    >
    <Heading size={700} marginBottom={10} className="h2">
       NFT farm
    </Heading>
         <Heading className="text" marginTop={15}>
                   Staking: {stakedNfts.length + stakedSpecialNfts.length + stakedEliteNfts.length + stakedBlessedNfts.length + stakedDamnedNfts.length}
         </Heading>
     <Heading className="text" marginTop={15}>
               Claimable rewards: {!isNaN(stakingRewards) && numberFormatter.format(stakingRewards / denominator)}{isNaN(stakingRewards) && 0.00} $MOO
            </Heading>
    </Card>
    </Pane>


<Pane marginBottom={10} marginTop={10}>
       <Heading size={700} className="h2">
          NFT Attributes
       </Heading>
    </Pane>

    <Pane display="flex" flexDirection={smallRes ? 'column' : 'row'}>
    <Card
       width={'100%'}
       marginTop={5}
       marginBottom={10}
       paddingTop={5}
       paddingBottom={20}
       elevation={1}
       display="flex"
       flexDirection="column"
       justifyContent="center"
       alignItems="center"
       className="card"
       >
       <Pane display="flex"
                      flexDirection={smallRes ? 'column' : 'row'}
                      flexWrap="wrap"
                      alignItems="center"
                      justifyContent="center">
       <div style={{ width: 60, margin: 5 }}>
        <Heading className="text" marginTop={15}>Display</Heading>
       </div>
       <div style={{ width: 80, margin: 5, paddingTop: 10 }}>
        <Toggle size="lg" checkedChildren="OFF" unCheckedChildren="ON" onChange={toggle}/>
       </div>
       </Pane>



       </Card>
    </Pane>

    <Pane marginBottom={30} marginTop={30}>
       <Heading size={700} className="h2">
          Your MooiarBulls NFTs
       </Heading>


<Pane display="flex"
        flexDirection={smallRes ? 'column' : 'row'}
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center">
       <div style={{ width: 60, margin: 5 }}>
        <Heading className="text" marginTop={15}>Filters</Heading>
       </div>
       <div style={{ width: 80, margin: 5, paddingTop: 10 }}>
         <ButtonToolbar>
         <IconButton icon={<AngleRightIcon />} onClick={() => handleOpen('left')}>
           Active: {getActiveFiltersCount()}
         </IconButton>
         </ButtonToolbar>
       </div>
       </Pane>

       <Pane display="flex"
        flexDirection={smallRes ? 'column' : 'row'}
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center">
        <div style={{ width: 80, margin: 5 }}>
          <Text fontSize={14} className="text" onClick={clearFilters} textDecoration="underline">(Reset all)</Text>
        </div>
       </Pane>

        <Drawer id="drawer-filter" placement={placement} open={open} onClose={() => setOpen(false)}>
               <Drawer.Header id="drawer-filter">
                 <Drawer.Title id="drawer-filter-title">Filters</Drawer.Title>
                 <Drawer.Actions>
                   <Button onClick={() => clearFilters()}>Reset all</Button>
                 </Drawer.Actions>
               </Drawer.Header>
               <Drawer.Body id="drawer-filter">
               <Heading className="text" marginTop={15}>Back</Heading><InputPicker placeholder='Back' value={backSearchValue} data={backData} style={{ width: 224 }} onChange={setBackSearchValue}/>
               <Heading className="text" marginTop={15}>Background</Heading><InputPicker placeholder='Background' value={backgroundSearchValue} data={backgroundData} style={{ width: 224 }} onChange={setBackgroundSearchValue}/>
               <Heading className="text" marginTop={15}>Clothes</Heading><InputPicker placeholder='Clothes' value={clothesSearchValue} data={clothesData} style={{ width: 224 }} onChange={setClothesSearchValue}/>
               <Heading className="text" marginTop={15}>Earring</Heading><InputPicker placeholder='Earring' value={earringSearchValue} data={earringData} style={{ width: 224 }} onChange={setEarringSearchValue}/>
               <Heading className="text" marginTop={15}>Eye flap</Heading><InputPicker placeholder='Eye flap' value={eyeFlapSearchValue} data={eyeFlapData} style={{ width: 224 }} onChange={setEyeFlapSearchValue}/>
               <Heading className="text" marginTop={15}>Glasses</Heading><InputPicker placeholder='Glasses' value={glassesSearchValue} data={glassesData} style={{ width: 224 }} onChange={setGlassesSearchValue}/>
               <Heading className="text" marginTop={15}>Hat</Heading><InputPicker placeholder='Hat' value={hatSearchValue} data={hatData} style={{ width: 224 }} onChange={setHatSearchValue}/>
               <Heading className="text" marginTop={15}>Head</Heading><InputPicker placeholder='Head' value={headSearchValue} data={headData} style={{ width: 224 }} onChange={setHeadSearchValue}/>
               <Heading className="text" marginTop={15}>Horns</Heading><InputPicker placeholder='Horns' value={hornsSearchValue} data={hornsData} style={{ width: 224 }} onChange={setHornsSearchValue}/>
               <Heading className="text" marginTop={15}>Mouth</Heading><InputPicker placeholder='Mouth' value={mouthSearchValue} data={mouthData} style={{ width: 224 }} onChange={setMouthSearchValue}/>
               <Heading className="text" marginTop={15}>Scars</Heading><InputPicker placeholder='Scars' value={scarsSearchValue} data={scarsData} style={{ width: 224 }} onChange={setScarsSearchValue}/>
               </Drawer.Body>
             </Drawer>
    </Pane>
    <Pane
    display="flex"
    flexWrap="wrap"
    flexDirection={smallRes ? 'column' : 'row'}
    justifyContent="start"
    alignItems="center"
    >
    {pending ? (
    <Spinner marginX="auto" marginY="auto" />
    ) : (
    nfts.map((nft: any, index: number) =>
    NFTCollection == nft.collection ? (
    <Card
    width={smallRes ? '100%' : '20%'}
    marginTop={10}
    marginBottom={10}
    marginLeft={smallRes ? '0' : '50px'}
    paddingTop={20}
    paddingBottom={20}
    elevation={1}
    key={index}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Image
       src={nft.media[0].url}
       alt="NFT image"
       width={200}
       borderRadius={20}
       />
    <Heading className="text" marginTop={15}>
       {nft.name}
    </Heading>
    <Pane>
       <Text fontSize={12} className="text">
          Collection:{' '}
       </Text>
       <Link
          href={`${explorerAddress}collections/${nft.collection}`}
          >
       <Text
          color="gray800"
          className="text"
          fontSize={12}
          textDecoration="underline"
          >
          {nft.collection}
       </Text>
       </Link>
    </Pane>
    <Pane>
       <Text fontSize={12} className="text">
          ID:{' '}
       </Text>
       <Link href={`${explorerAddress}nfts/${nft.identifier}`}>
       <Text
          color="gray800"
          className="text"
          fontSize={12}
          textDecoration="underline"
          >
          {nft.identifier}
       </Text>
       </Link>
    </Pane>
    <Pane>
       <Text fontSize={16} className="text">
          Rank:{' '}
       </Text>
       <Text
          color="gray800"
          className="text"
          fontSize={16}
          textDecoration="underline"
          >
          {ranks[parseInt(nft.name.split("#").pop())]}
       </Text>
    </Pane>
      <Pane marginTop={10} hidden={!displayAttributesChecked}>

                   <Heading className="text" marginTop={15}>
                      Attributes
                   </Heading>
                   </Pane>

                   {nft.metadata && nft.metadata.attributes && nft.metadata.attributes.map((attr) => <Pane hidden={!displayAttributesChecked}><Text fontSize={12} className="text">{attr.trait_type}:{' '}</Text>
                   <Text fontSize={12} className="text">{attr.value}</Text></Pane>)}
    <Pane paddingTop={5}>
       <Button
          marginTop={20}
          appearance="primary"
          intent={getStakeButtonIntent(nft.identifier)}
          fontSize={14}
          paddingTop={20}
          paddingBottom={20}
          onClick={stake(stakeTransaction,nft.identifier)}
          disabled={!stakingEnabled || stakedCount>=stakingLimit}>
       {getStakeButtonText(nft.identifier)}
       </Button>
    </Pane>
    </Card>
    ) : null
    )
    )}
    {!pending && nfts.length === 0 &&
    <Text>There are no NFTs in your wallet.</Text>
    }
    </Pane>

    <Pane marginBottom={30} marginTop={30}>
       <Heading size={700} className="h2">
          Your MooiarVIP Passes NFTs
       </Heading>
    </Pane>
    <Pane
    display="flex"
    flexWrap="wrap"
    flexDirection={smallRes ? 'column' : 'row'}
    justifyContent="start"
    alignItems="center"
    >
    {pending ? (
    <Spinner marginX="auto" marginY="auto" />
    ) : (
    vipNfts.map((vipNft: any, index: number) =>
    VIPNFTCollection == vipNft.collection ? (
    <Card
    width={smallRes ? '100%' : '20%'}
    marginTop={10}
    marginBottom={10}
    marginLeft={smallRes ? '0' : '50px'}
    paddingTop={20}
    paddingBottom={20}
    elevation={1}
    key={index}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Image
       src={vipNft.media[0].url}
       alt="NFT image"
       width={200}
       borderRadius={20}
       />
    <Heading className="text" marginTop={15}>
       {vipNft.name}
    </Heading>
    <Pane>
       <Text fontSize={12} className="text">
          Collection:{' '}
       </Text>
       <Link
          href={`${explorerAddress}collections/${vipNft.collection}`}
          >
       <Text
          color="gray800"
          className="text"
          fontSize={12}
          textDecoration="underline"
          >
          {vipNft.collection}
       </Text>
       </Link>
    </Pane>
    <Pane>
       <Text fontSize={12} className="text">
          ID:{' '}
       </Text>
       <Link href={`${explorerAddress}nfts/${vipNft.identifier}`}>
       <Text
          color="gray800"
          className="text"
          fontSize={12}
          textDecoration="underline"
          >
          {vipNft.identifier}
       </Text>
       </Link>
    </Pane>
      <Pane marginTop={10}  hidden={!displayAttributesChecked}>

                   <Heading className="text" marginTop={15}>
                      Attributes
                   </Heading>
                   </Pane>

                   {vipNft.metadata.attributes.map((attr) => <Pane hidden={!displayAttributesChecked}><Text fontSize={12} className="text">{attr.trait_type}:{' '}</Text>
                   <Text fontSize={12} className="text">{attr.value}</Text></Pane>)}
    <Pane paddingTop={5}>
       <Button
          marginTop={20}
          appearance="primary"
          fontSize={14}
          paddingTop={20}
          paddingBottom={20}
          disabled={true}>
       Stake
       </Button>
    </Pane>
    </Card>
    ) : null
    )
    )}
    {!pending && vipNfts.length === 0 &&
    <Text>There are no NFTs in your wallet.</Text>
    }
    </Pane>
    </>
  );
};

export default AccountTab;
