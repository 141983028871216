    export const backData = ['Angel Wings','Benjo','Devil Wings','None','Pickaxe','Remington','Rolling Block','Shotgun','Shovel',
    'Smoothbore','Trumpet','Winchester'].map(item => ({ label: item, value: item }));

    export const backgroundData = ['Andy\'s Room','Blackberry','Blue Zodiac','Bush','Chathams Blue','Cherrywood','Clairvoyant','Cold Storm',
    'Cyan Gradient','Deep Forest Green','Desert Dawn','Desert Day 1','Desert Day 2','Desert Day 3','Desert Night','Desert Sunset 1',
    'Desert Sunset 2','Diesel','Firefly','For the Ladies','Green Gradient','Heatwave','Heaven','Hell','Hill','Jon','Lucky Mooiar',
    'Melanzane','Midnight','Moorro Sign','Nightfall','Palm Leaf','Pantone','Pink Gradient','Purple Gradient','Rainbow','Ranger',
    'Road','Tarawera','Thatch Green','The Night in the West 1','The Night in the West 2','Timber Green','Twilight','Valentino',
    'Violet Gradient','Westworld','White'].map(item => ({ label: item, value: item }));

    export const headData = ['Angel','Black','Brown','Devil','White'].map(item => ({ label: item, value: item }));

    export const clothesData = ['Acacia','Afrocarpus','Aleurites','Alnus','Anacardium','Aralia','Bombax','Brosimum','Bruxus','Bucida','Caesalpinia',
    'Callistemon','Calophyllum','Cassia','Catalpa','Cedrus','Chuck','Combretum','Conocarpus','Cordyline','Corymbia','Cussonia',
    'Divine','Django','Durio','Elaeis','Fraxinus','Guibourtia','Hymenaea','Hyophorbe','Illicium','Khaya','Lucky Luke','Lumnitzera',
    'Machaerium','Maius','Malpighia','Man in Black','Melia','Moody','Mooriarchi','Moorro','Myoporum','Persea','Pithecellobium',
    'Populus','Prunus','Senegalia','Talipariti','Thuja','Vachellia'].map(item => ({ label: item, value: item }));

    export const scarsData = ['None','Scars'].map(item => ({ label: item, value: item }));

    export const eyeFlapData = ['Eye Flap','Eye Flap BTC','Eye Flap EGLD','Eye Flap MEX','Eye Flap RIDE','Eye Flap X','Moorro Mask','None'].map(item => ({ label: item, value: item }));

    export const hatData = ['Boss Of The Plains','Bounty Hunter','Bowler Hat','Bowler Hat Turquoise','Campaign Hat','Cattleman',
    'Cowboy Hat Black','Cowboy Hat Brown','Deputy Hat','Elrond Hat','Gambler Hat Elrond','Gambler Hat Elrond Dark',
    'Gambler Hat Elrond Holes','Gaucho Hat','Gaucho Hat Holes','Gaung Baung Black','Gaung Baung White','Lucky Hat','Moody Hat',
    'Mooriarchi Hat','Moorris Hat','Moorro Hat','Nimbus','None','Outback Hat Brown','Rodeo Hat','Sheriff Hat Brown',
    'Sheriff Hat Gray','Sheriff Hat Light Brown','Slaver','Sombrero Hat Dark Blue','Sombrero Hat Dark Blue Holes',
    'Sombrero Hat Dark Gray','Sombrero Hat Dark Green','Sombrero Hat Dark Red','Sombrero Hat Dark Violet','Sombrero Hat Light Black',
    'Sombrero Hat Light Black Holes','Sombrero Hat Light Blue','Sombrero Hat Light Green','Sombrero Hat Light Orange',
    'Sombrero Hat Light Red','Sombrero Hat Light Turquoise'].map(item => ({ label: item, value: item }));

    export const mouthData = ['Aluminium','Brass','Classy','Cuba','Dominican','Excelsior','Gold','Gunmetal','Kira','Moustache',
    'None','Puff Puff','Quentin','Rhodium','Rose','Rossi Sera','Savinelli','Silver','Spike','Titanium','Wheat Spike','Zinc',
    'Zirconia'].map(item => ({ label: item, value: item }));

    export const earringData = ['Agate','Black Tourmaline','Blizzard Cross','Garnet','Jade','Jasper','None','Onyx','Sardonyx','Silver Leaf',
    'Sodalite','Spinel'].map(item => ({ label: item, value: item }));

   export const hornsData = ['Ametist Black','Ametist Brown','Ametist White','Devil Horns','Diamond Black','Diamond Brown',
   'Diamond White','Duke Black','Duke Brown','Duke White','Goldy Black','Goldy Brown','Goldy White','Hydro Black',
   'Hydro Brown','Hydro White','Nitrous Black','Nitrous Brown','Nitrous White','None','Tank Black','Tank Brown',
   'Tank White'].map(item => ({ label: item, value: item }));

   export const glassesData = ['Glasses Django','Glasses Mooriarchi','None'].map(item => ({ label: item, value: item }));
