import { Ui } from '@elrondnetwork/dapp-utils';
import {
  Combobox,
  Pane,
  Heading,
  Paragraph,
  Link,
  Text,
  Card,
  Spinner,
  Image,
  Button,
  HatIcon
} from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';
import * as Dapp from '@elrondnetwork/dapp';
import { getNfts, getStakedNfts, getNft, getScValueQuery } from '../../apiEndpoints';
import { useEffect, useRef, useState } from 'react';
import { NFTCollection, ranks, denominator } from '../../config';
import InputNumber from 'rsuite/InputNumber';
import useNewTransaction from '../useNewTransaction';
import { RawTransactionType } from '../../helpers/types';
import { NFTStakeContract } from '../../config';
import {Address} from '@elrondnetwork/erdjs';
import blessedPoolLogo from '../../static/media/blessedpool.gif';
import damnedPoolLogo from '../../static/media/damnedpool.gif';
import elitePoolLogo from '../../static/media/mecpool.gif';
import specialPoolLogo from '../../static/media/specialpool.gif';
import mooiarPoolLogo from '../../static/media/mooiarpool.gif';
import { Toggle, InputPicker, Drawer, ButtonToolbar, IconButton, Placeholder } from 'rsuite';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import {backData, backgroundData, headData, clothesData, scarsData, eyeFlapData, hatData, mouthData, earringData, hornsData, glassesData} from '../../traitsData';


const AccountStakedTab = () => {
  const { account, address, explorerAddress } = Dapp.useContext();

  const [sortType, setSortType] = useState("Rank: High to Low");

  const [refresh, setRefresh] = useState(false);
  const [nfts, setNftsList] = useState([]);
  const [blessedNfts, setBlessedNftsList] = useState([]);
  const [damnedNfts, setDamnedNftsList] = useState([]);
  const [eliteNfts, setEliteNftsList] = useState([]);
  const [specialNfts, setSpecialNftsList] = useState([]);
  const [blessedNftsNonces, setBlessedNftsNoncesList] = useState([]);
  const [damnedNftsNonces, setDamnedNftsNoncesList] = useState([]);
  const [eliteNftsNonces, setEliteNftsNoncesList] = useState([]);
  const [specialNftsNonces, setSpecialNftsNoncesList] = useState([]);
  const [pending, setPending] = useState(false);
  const [elitePending, setElitePending] = useState(false);
  const [specialPending, setSpecialPending] = useState(false);
  const [blessedPending, setBlessedPending] = useState(false);
  const [damnedPending, setDamnedPending] = useState(false);
  const [rewards, setRewards] = useState(null);
  const [blessedRewards, setBlessedRewards] = useState(null);
  const [damnedRewards, setDamnedRewards] = useState(null);
  const [eliteRewards, setEliteRewards] = useState(null);
  const [specialRewards, setSpecialRewards] = useState(null);
  const [standardStakingRewardsPerEpoch, setStandardStakingRewardsPerEpoch] = useState(null);
  const [specialStakingRewardsPerEpoch, setSpecialStakingRewardsPerEpoch] = useState(null);
  const [eliteStakingRewardsPerEpoch, setEliteStakingRewardsPerEpoch] = useState(null);
  const [blessedStakingRewardsPerEpoch, setBlessedStakingRewardsPerEpoch] = useState(null);
  const [damnedStakingRewardsPerEpoch, setDamnedStakingRewardsPerEpoch] = useState(null);
  const numberFormatter = new Intl.NumberFormat();

  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();

  const [backSearchValue, setBackSearchValue] = useState(null);
  const [backgroundSearchValue, setBackgroundSearchValue] = useState(null);
  const [clothesSearchValue, setClothesSearchValue] = useState(null);
  const [earringSearchValue, setEarringSearchValue] = useState(null);
  const [eyeFlapSearchValue, setEyeFlapSearchValue] = useState(null);
  const [glassesSearchValue, setGlassesSearchValue] = useState(null);
  const [hatSearchValue, setHatSearchValue] = useState(null);
  const [headSearchValue, setHeadSearchValue] = useState(null);
  const [hornsSearchValue, setHornsSearchValue] = useState(null);
  const [mouthSearchValue, setMouthSearchValue] = useState(null);
  const [scarsSearchValue, setScarsSearchValue] = useState(null);

  const [displayAttributesChecked, setDisplayAttributesChecked] = useState(false);

  const mounted = useRef(true);
  const smallRes = useMediaQuery({
    query: '(max-width: 600px)',
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setPending(true);
    setBlessedPending(true);
    setDamnedPending(true);
    setElitePending(true);
    setSpecialPending(true);
    setRefresh(true);

    const fetchStandardStakingRewardsPerEpoch = async () => {
      const settings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getTokensRewardPerEpoch'})};
      fetch(getScValueQuery(), settings)
        .then(response => response.json())
        .then(json => setStandardStakingRewardsPerEpoch(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)));
    };

    const fetchBlessedStakingRewardsPerEpoch = async () => {
      const settings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getBlessedPoolTokensRewardPerEpoch'})};
      fetch(getScValueQuery(), settings)
        .then(response => response.json())
        .then(json => setBlessedStakingRewardsPerEpoch(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)));
    };

    const fetchDamnedStakingRewardsPerEpoch = async () => {
      const settings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getDamnedPoolTokensRewardPerEpoch'})};
      fetch(getScValueQuery(), settings)
        .then(response => response.json())
        .then(json => setDamnedStakingRewardsPerEpoch(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)));
    };

    const fetchEliteStakingRewardsPerEpoch = async () => {
      const settings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getElitePoolTokensRewardPerEpoch'})};
      fetch(getScValueQuery(), settings)
        .then(response => response.json())
        .then(json => setEliteStakingRewardsPerEpoch(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)));
    };

    const fetchSpecialStakingRewardsPerEpoch = async () => {
      const settings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getSpecialPoolTokensRewardPerEpoch'})};
      fetch(getScValueQuery(), settings)
        .then(response => response.json())
        .then(json => setSpecialStakingRewardsPerEpoch(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)));
    };

    const fetchStakingRewards = async () => {
      const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getStakingRewards', caller: address})};

      fetch(getScValueQuery(), fetchSettings)
        .then(response => response.json())
        .then(json => setRewards(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)));
    };

    const fetchBlessedStakingRewards = async () => {
      const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getBlessedStakingRewards', caller: address})};

      fetch(getScValueQuery(), fetchSettings)
        .then(response => response.json())
        .then(json => setBlessedRewards(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)));
    };

    const fetchDamnedStakingRewards = async () => {
      const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getDamnedStakingRewards', caller: address})};

      fetch(getScValueQuery(), fetchSettings)
        .then(response => response.json())
        .then(json => setDamnedRewards(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)));
    };

    const fetchEliteStakingRewards = async () => {
      const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getEliteStakingRewards', caller: address})};

      fetch(getScValueQuery(), fetchSettings)
        .then(response => response.json())
        .then(json => setEliteRewards(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)));
    };

    const fetchSpecialStakingRewards = async () => {
      const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getSpecialStakingRewards', caller: address})};

      fetch(getScValueQuery(), fetchSettings)
        .then(response => response.json())
        .then(json => setSpecialRewards(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)));
    };

    const fetchEliteNftsNonces = async () => {
       const settings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getElitePoolNonces'})};

      const response = await fetch(getScValueQuery(), settings);
      const responseData = await response.json();
      const eliteNftsNonces = responseData.data.data.returnData.map(item => Buffer.from(item,'base64').toString('hex'), 16);
      setEliteNftsNoncesList(eliteNftsNonces);
    };

    const fetchBlessedNftsNonces = async () => {
       const settings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getBlessedPoolNonces'})};

      const response = await fetch(getScValueQuery(), settings);
      const responseData = await response.json();
      const blessedNftsNonces = responseData.data.data.returnData.map(item => Buffer.from(item,'base64').toString('hex'), 16);
      setBlessedNftsNoncesList(blessedNftsNonces);
    };

    const fetchDamnedNftsNonces = async () => {
       const settings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getDamnedPoolNonces'})};

      const response = await fetch(getScValueQuery(), settings);
      const responseData = await response.json();
      const damnedNftsNonces = responseData.data.data.returnData.map(item => Buffer.from(item,'base64').toString('hex'), 16);
      setDamnedNftsNoncesList(damnedNftsNonces);
    };

    const fetchSpecialNftsNonces = async () => {
       const settings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getSpecialPoolNonces'})};

      const response = await fetch(getScValueQuery(), settings);
      const responseData = await response.json();
      const specialNftsNonces = responseData.data.data.returnData.map(item => Buffer.from(item,'base64').toString('hex'), 16);
      setSpecialNftsNoncesList(specialNftsNonces);
    };

    const fetchNfts = async () => {
       const fetchStakedNftsSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getStandardStakedNfts', args: [new Address(address).hex()]})};

      const fetchedNftsResponse = await fetch(getScValueQuery(), fetchStakedNftsSettings);
      const fetchedNftsResponseData = await fetchedNftsResponse.json();
      const identifiersHex = fetchedNftsResponseData.data.data.returnData.map(item => Buffer.from(item,'base64').toString('hex'), 16) + "";
      const identifiersHexList = identifiersHex.replaceAll(",","%2CMOO-bd3a9f-");

      if (identifiersHexList.length !== 0) {
        const response = await fetch(getStakedNfts(identifiersHexList), { signal });
        let data = await response.json();
        data = filterNfts(data);
        data = sortNfts(data);
        setNftsList(data);
        setPending(false);
      } else {
        setNftsList([]);
        setPending(false);
        setRefresh(false);
      }
    };

    const fetchEliteNfts = async () => {
       const fetchStakedNftsSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getStakedEliteNfts', args: [new Address(address).hex()]})};

      const fetchedNftsResponse = await fetch(getScValueQuery(), fetchStakedNftsSettings);
      const fetchedNftsResponseData = await fetchedNftsResponse.json();
      const identifiersHex = fetchedNftsResponseData.data.data.returnData.map(item => Buffer.from(item,'base64').toString('hex'), 16) + "";
      const identifiersHexList = identifiersHex.replaceAll(",","%2CMOO-bd3a9f-");

      if (identifiersHexList.length !== 0) {
        const response = await fetch(getStakedNfts(identifiersHexList), { signal });
        let data = await response.json();
        data = filterNfts(data);
        data = sortNfts(data);
        setEliteNftsList(data);
        setElitePending(false);
      } else {
        setEliteNftsList([]);
        setElitePending(false);
        setRefresh(false);
      }
  };

  const fetchBlessedNfts = async () => {
     const fetchStakedNftsSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getStakedBlessedNfts', args: [new Address(address).hex()]})};

    const fetchedNftsResponse = await fetch(getScValueQuery(), fetchStakedNftsSettings);
    const fetchedNftsResponseData = await fetchedNftsResponse.json();
    const identifiersHex = fetchedNftsResponseData.data.data.returnData.map(item => Buffer.from(item,'base64').toString('hex'), 16) + "";
    const identifiersHexList = identifiersHex.replaceAll(",","%2CMOO-bd3a9f-");

    if (identifiersHexList.length !== 0) {
      const response = await fetch(getStakedNfts(identifiersHexList), { signal });
      let data = await response.json();
      data = filterNfts(data);
      data = sortNfts(data);
      setBlessedNftsList(data);
      setBlessedPending(false);
    } else {
      setBlessedNftsList([]);
      setBlessedPending(false);
      setRefresh(false);
    }
  };

  const fetchDamnedNfts = async () => {
    const fetchStakedNftsSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getStakedDamnedNfts', args: [new Address(address).hex()]})};

    const fetchedNftsResponse = await fetch(getScValueQuery(), fetchStakedNftsSettings);
    const fetchedNftsResponseData = await fetchedNftsResponse.json();
    const identifiersHex = fetchedNftsResponseData.data.data.returnData.map(item => Buffer.from(item,'base64').toString('hex'), 16) + "";
    const identifiersHexList = identifiersHex.replaceAll(",","%2CMOO-bd3a9f-");

    if (identifiersHexList.length !== 0) {
      const response = await fetch(getStakedNfts(identifiersHexList), { signal });
      let data = await response.json();
      data = filterNfts(data);
      data = sortNfts(data);
      setDamnedNftsList(data);
      setDamnedPending(false);
    } else {
      setDamnedNftsList([]);
      setDamnedPending(false);
      setRefresh(false);
    }
  };

    const fetchSpecialNfts = async () => {
       const fetchStakedNftsSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTStakeContract, funcName: 'getStakedSpecialNfts', args: [new Address(address).hex()]})};

      const fetchedNftsResponse = await fetch(getScValueQuery(), fetchStakedNftsSettings);
      const fetchedNftsResponseData = await fetchedNftsResponse.json();
      const identifiersHex = fetchedNftsResponseData.data.data.returnData.map(item => Buffer.from(item,'base64').toString('hex'), 16) + "";
      const identifiersHexList = identifiersHex.replaceAll(",","%2CMOO-bd3a9f-");

      if (identifiersHexList.length !== 0) {
        const response = await fetch(getStakedNfts(identifiersHexList), { signal });
        let data = await response.json();
        data = filterNfts(data);
        data = sortNfts(data);
        setSpecialNftsList(data);
        setSpecialPending(false);
      } else {
        setSpecialNftsList([]);
        setSpecialPending(false);
      }
    };

    if (address) {
      fetchNfts();

      fetchStandardStakingRewardsPerEpoch();
      fetchSpecialStakingRewardsPerEpoch();
      fetchEliteStakingRewardsPerEpoch();
      fetchBlessedStakingRewardsPerEpoch();
      fetchDamnedStakingRewardsPerEpoch();

      fetchSpecialNfts();
      fetchEliteNfts();
      fetchBlessedNfts();
      fetchDamnedNfts();

      fetchStakingRewards();
      fetchSpecialStakingRewards();
      fetchEliteStakingRewards();
      fetchBlessedStakingRewards();
      fetchDamnedStakingRewards();

      fetchSpecialNftsNonces();
      fetchEliteNftsNonces();
      fetchBlessedNftsNonces();
      fetchDamnedNftsNonces();
    }

    return () => {
      mounted.current = false;
    };
  }, [address, sortType, backSearchValue, backgroundSearchValue, clothesSearchValue, earringSearchValue, eyeFlapSearchValue,
  glassesSearchValue, hatSearchValue, headSearchValue, hornsSearchValue, mouthSearchValue, scarsSearchValue]);

    function getUnstakeButtonIntent(nftIdentifier) {
      const nftNonce = nftIdentifier.split("-").pop();
      if (specialNftsNonces.some((item) => nftNonce === item)) {
        return "success";
      }

      if (eliteNftsNonces.some((item) => nftNonce === item)) {
        return "danger";
      }

      if (blessedNftsNonces.some((item) => nftNonce === item)) {
        return "danger";
      }

      if (damnedNftsNonces.some((item) => nftNonce === item)) {
        return "danger";
      }

      return "none";
    }

  function isEligibleForSpecialPool(nftIdentifier) {
    return specialNftsNonces.some(specialNftNonce => (specialNftNonce === nftIdentifier.split("-").pop()));
  }

  function sortNfts(data) {
    if (sortType == null) {
      console.log("no sort type");
      return data;
    }

    if (sortType === "Rank: High to Low") {
      data = data.sort((nft1, nft2) => ranks[parseInt(nft1.name.split("#").pop())] - ranks[parseInt(nft2.name.split("#").pop())]);
    } else if (sortType === "Rank: Low to High") {
      data = data.sort((nft1, nft2) => ranks[parseInt(nft2.name.split("#").pop())] - ranks[parseInt(nft1.name.split("#").pop())]);
    } else if (sortType === "Oldest Minted") {
      data = data.sort((nft1, nft2) => parseInt(nft1.identifier.split("-").pop(), 16) - parseInt(nft2.identifier.split("-").pop(), 16));
    } else if (sortType === "Recently Minted") {
      data = data.sort((nft1, nft2) => parseInt(nft2.identifier.split("-").pop(), 16) - parseInt(nft1.identifier.split("-").pop(), 16));
    }

    return data;
  }

  function filterNfts(data) {
    data = filterByTrait(data, 'Back', backSearchValue);
    data = filterByTrait(data, 'Background', backgroundSearchValue);
    data = filterByTrait(data, 'Clothes', clothesSearchValue);
    data = filterByTrait(data, 'Earring', earringSearchValue);
    data = filterByTrait(data, 'Eye flap', eyeFlapSearchValue);
    data = filterByTrait(data, 'Glasses', glassesSearchValue);
    data = filterByTrait(data, 'Hat', hatSearchValue);
    data = filterByTrait(data, 'Head', headSearchValue);
    data = filterByTrait(data, 'Horns', hornsSearchValue);
    data = filterByTrait(data, 'Mouth', mouthSearchValue);
    data = filterByTrait(data, 'Scars', scarsSearchValue);

    return data;
  }

  function filterByTrait(data, traitType, traitValue){
    if (traitValue != null) {
             data = data.map(nft => ({
                                        ...nft,
                                        attributes: nft.metadata.attributes
                                          .filter(attribute =>
                                    attribute.trait_type === traitType && attribute.value === traitValue
                                        )
                                      }))
                                      .filter(item => item.attributes.length > 0);
    }

    return data;
  }

  function toggle()  {
    setDisplayAttributesChecked(!displayAttributesChecked);
  }

  function clearFilters()  {
    setBackgroundSearchValue(null);
    setBackSearchValue(null);
    setClothesSearchValue(null);
    setEarringSearchValue(null);
    setEyeFlapSearchValue(null);
    setGlassesSearchValue(null);
    setHatSearchValue(null);
    setHeadSearchValue(null);
    setHornsSearchValue(null);
    setMouthSearchValue(null);
    setScarsSearchValue(null);
  }

  function getActiveFiltersCount() {
    let count = 0;
    if (backgroundSearchValue != null) {count+=1;}
    if (backSearchValue != null) {count+=1;}
    if (clothesSearchValue != null) {count+=1;}
    if (earringSearchValue != null) {count+=1;}
    if (eyeFlapSearchValue != null) {count+=1;}
    if (glassesSearchValue != null) {count+=1;}
    if (hatSearchValue != null) {count+=1;}
    if (headSearchValue != null) {count+=1;}
    if (hornsSearchValue != null) {count+=1;}
    if (mouthSearchValue != null) {count+=1;}
    if (scarsSearchValue != null) {count+=1;}
    return count;
  }

  function isFilterEnabled() {
    return backSearchValue!=null || backgroundSearchValue!=null || clothesSearchValue!=null || earringSearchValue!=null ||
      eyeFlapSearchValue!=null || glassesSearchValue!=null || hatSearchValue!=null || headSearchValue!=null ||
      hornsSearchValue!=null || mouthSearchValue!=null || scarsSearchValue!=null;
  }

    function getStandardRewardsCalculated() {
      if (isNaN(rewards)) {
        return NaN;
      }

      let rewardsCalculated = rewards;
      if (!isNaN(blessedRewards)) {
        rewardsCalculated = rewardsCalculated - blessedRewards;
      }
      if (!isNaN(damnedRewards)) {
        rewardsCalculated = rewardsCalculated - damnedRewards;
      }
      if (!isNaN(eliteRewards)) {
        rewardsCalculated = rewardsCalculated - eliteRewards;
      }
      if (!isNaN(specialRewards)) {
        rewardsCalculated = rewardsCalculated - specialRewards;
      }

      return rewardsCalculated;
    }

    const handleOpen = key => {
        setOpen(true);
        setPlacement(key);
      };

    const sendTransaction = Dapp.useSendTransaction();
    const newTransaction = useNewTransaction();
    const send =
      (transaction: RawTransactionType, nftIdentifier: string) => async (e: React.MouseEvent) => {
        const nftCollectionHex = Buffer.from(NFTCollection).toString('hex');
        const nftNonce = nftIdentifier.split("-").pop();
        transaction.data = `unstake@${nftCollectionHex}@${nftNonce}`;
        e.preventDefault();
        sendTransaction({
          transaction: newTransaction(transaction),
          callbackRoute: '/dashboard',
        });
      };

    const unstakeTransaction: RawTransactionType = {
      receiver: NFTStakeContract,
      value: '0',
      data: '"unstake',
      gasLimit: 125000000,
    };

    const claim = (transaction: RawTransactionType) => async (e: React.MouseEvent) => {
      transaction.data = `claim`;
      e.preventDefault();
      sendTransaction({
        transaction: newTransaction(transaction),
        callbackRoute: '/dashboard',
      });
    };

    const claimTransaction: RawTransactionType = {
      receiver: NFTStakeContract,
      value: '0',
      data: 'claim',
      gasLimit: 150000000,
    };

    const moveToSpecialPool = (transaction: RawTransactionType, nftIdentifier: string) => async (e: React.MouseEvent) => {
      const nftNonce = nftIdentifier.split("-").pop();
      transaction.data = `moveToSpecialPool@${nftNonce}`;
      e.preventDefault();
      sendTransaction({
        transaction: newTransaction(transaction),
        callbackRoute: '/dashboard',
      });
    };

    const moveToSpecialPoolTransaction: RawTransactionType = {
      receiver: NFTStakeContract,
      value: '0',
      gasLimit: nfts.length > 95 ? 150000000 : 75000000,
    };

  return (
    <>
    <Pane marginBottom={10} marginTop={10}>
       <Heading size={700} className="h2">
          Your staking pools ({blessedNfts.length + damnedNfts.length + eliteNfts.length + specialNfts.length + nfts.length})
       </Heading>
    </Pane>
    <Pane display="flex" flexDirection={smallRes ? 'column' : 'row'}>
    <Card
    width={smallRes ? '100%' : '20%'}
    marginTop={5}
    marginBottom={10}
    paddingTop={5}
    paddingBottom={20}
    elevation={1}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Heading className="text" marginTop={15}>
       Mooiar pool
    </Heading>
    <Image
       src={mooiarPoolLogo}
       alt="Mooiar Staking Pool image"
       width={200}
       borderRadius={20}
       />
    <br/>
    <Pane>
       <Text fontSize={28} className="text">
          {nfts.length}/{blessedNfts.length + damnedNfts.length + eliteNfts.length + specialNfts.length + nfts.length}
       </Text>
    </Pane>
    <br/>
    <Pane>
       <Text fontSize={20} className="text" textDecoration="underline">
          Rewards
       </Text>
       <Text fontSize={20} className="text" marginTop={15}>
          : {!isNaN(getStandardRewardsCalculated()) && numberFormatter.format(getStandardRewardsCalculated() / denominator)}{isNaN(getStandardRewardsCalculated()) && 0.00} $MOO
       </Text>
    </Pane>
    <br/>
    <Pane>
       <Text fontSize={14} className="text" fontStyle="italic">
          *daily reward: 0 $MOO/day
       </Text>
    </Pane>
    </Card>
    <Card
    width={smallRes ? '100%' : '20%'}
    marginTop={5}
    marginBottom={10}
    paddingTop={5}
    paddingBottom={20}
    elevation={1}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Heading className="text" marginTop={15}>
       Special pool
    </Heading>
    <Image
       src={specialPoolLogo}
       alt="Special Staking Pool image"
       width={200}
       borderRadius={20}
       />
    <br/>
    <Pane>
       <Text fontSize={28} className="text">
          {specialNfts.length}/{blessedNfts.length + damnedNfts.length + eliteNfts.length + specialNfts.length + nfts.length}
       </Text>
    </Pane>
    <br/>
    <Pane>
       <Text fontSize={20} className="text" textDecoration="underline">
          Rewards
       </Text>
       <Text fontSize={20} className="text">
          : {!isNaN(specialRewards) && numberFormatter.format(specialRewards / denominator)}{isNaN(specialRewards) && 0} $MOO
       </Text>
    </Pane>
    <br/>
    <Pane>
       <Text fontSize={14} className="text" fontStyle="italic">
          *daily reward: {numberFormatter.format(specialStakingRewardsPerEpoch / denominator)} $MOO/day
       </Text>
    </Pane>
    </Card>
    <Card
    width={smallRes ? '100%' : '20%'}
    marginTop={5}
    marginBottom={10}
    paddingTop={5}
    paddingBottom={20}
    elevation={1}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Heading className="text" marginTop={15}>
       MEC pool
    </Heading>
    <Image
       src={elitePoolLogo}
       alt="Elite Staking Pool image"
       width={200}
       borderRadius={20}
       />
    <br/>
    <Pane>
       <Text fontSize={28} className="text">
          {eliteNfts.length}/{blessedNfts.length + damnedNfts.length + eliteNfts.length + specialNfts.length + nfts.length}
       </Text>
    </Pane>
    <br/>
    <Pane>
       <Text fontSize={20} className="text" textDecoration="underline">
          Rewards
       </Text>
       <Text fontSize={20} className="text">
          : {!isNaN(eliteRewards) && numberFormatter.format(eliteRewards / denominator)}{isNaN(eliteRewards) && 0.00} $MOO
       </Text>
    </Pane>
    <br/>
    <Pane>
       <Text fontSize={14} className="text" fontStyle="italic">
          *daily reward: {numberFormatter.format(eliteStakingRewardsPerEpoch / denominator)} $MOO/day
       </Text>
    </Pane>
    </Card>

    <Card
    width={smallRes ? '100%' : '20%'}
    marginTop={5}
    marginBottom={10}
    paddingTop={5}
    paddingBottom={20}
    elevation={1}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Heading className="text" marginTop={15}>
       Blessed pool
    </Heading>
    <Image
       src={blessedPoolLogo}
       alt="Blessed Staking Pool image"
       width={200}
       borderRadius={20}
       />
    <br/>
    <Pane>
       <Text fontSize={28} className="text">
          {blessedNfts.length}/{blessedNfts.length + damnedNfts.length + eliteNfts.length + specialNfts.length + nfts.length}
       </Text>
    </Pane>
    <br/>
    <Pane>
       <Text fontSize={20} className="text" textDecoration="underline">
          Rewards
       </Text>
       <Text fontSize={20} className="text">
          : {!isNaN(blessedRewards) && numberFormatter.format(blessedRewards / denominator)}{isNaN(blessedRewards) && 0.00} $MOO
       </Text>
    </Pane>
    <br/>
    <Pane>
       <Text fontSize={14} className="text" fontStyle="italic">
          *daily reward: {numberFormatter.format(blessedStakingRewardsPerEpoch / denominator)} $MOO/day
       </Text>
    </Pane>
    </Card>

    <Card
    width={smallRes ? '100%' : '20%'}
    marginTop={5}
    marginBottom={10}
    paddingTop={5}
    paddingBottom={20}
    elevation={1}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Heading className="text" marginTop={15}>
       Damned pool
    </Heading>
    <Image
       src={damnedPoolLogo}
       alt="Damned Staking Pool image"
       width={200}
       borderRadius={20}
       />
    <br/>
    <Pane>
       <Text fontSize={28} className="text">
          {damnedNfts.length}/{blessedNfts.length + damnedNfts.length + eliteNfts.length + specialNfts.length + nfts.length}
       </Text>
    </Pane>
    <br/>
    <Pane>
       <Text fontSize={20} className="text" textDecoration="underline">
          Rewards
       </Text>
       <Text fontSize={20} className="text">
          : {!isNaN(damnedRewards) && numberFormatter.format(damnedRewards / denominator)}{isNaN(damnedRewards) && 0.00} $MOO
       </Text>
    </Pane>
    <br/>
    <Pane>
       <Text fontSize={14} className="text" fontStyle="italic">
          *daily reward: {numberFormatter.format(damnedStakingRewardsPerEpoch / denominator)} $MOO/day
       </Text>
    </Pane>
    </Card>
    </Pane>
    <Pane display="flex" flexDirection={smallRes ? 'column' : 'row'}>
    <Card
       width={'100%'}
       marginTop={5}
       marginBottom={10}
       paddingTop={5}
       paddingBottom={20}
       elevation={1}
       display="flex"
       flexDirection="column"
       justifyContent="center"
       alignItems="center"
       className="card"
       >
       <Button
          marginTop={20}
          appearance="primary"
          fontSize={14}
          iconBefore={HatIcon}
          paddingTop={20}
          paddingBottom={20}
          onClick={claim(claimTransaction)}
          disabled={!rewards}>
       Claim all
       </Button>
       <Heading className="text" marginTop={15}>
          ({!isNaN(rewards) && numberFormatter.format(rewards / denominator)}{isNaN(rewards) && 0.00} $MOO)
       </Heading>
    </Card>
    </Pane>

    <Pane marginBottom={10} marginTop={10}>
       <Heading size={700} className="h2">
          NFT Attributes
       </Heading>
    </Pane>

    <Pane display="flex" flexDirection={smallRes ? 'column' : 'row'}>
    <Card
       width={'100%'}
       marginTop={5}
       marginBottom={10}
       paddingTop={5}
       paddingBottom={20}
       elevation={1}
       display="flex"
       flexDirection="column"
       justifyContent="center"
       alignItems="center"
       className="card"
       >
       <Pane display="flex"
                      flexDirection={smallRes ? 'column' : 'row'}
                      flexWrap="wrap"
                      alignItems="center"
                      justifyContent="center">
       <div style={{ width: 60, margin: 5 }}>
        <Heading className="text" marginTop={15}>Display</Heading>
       </div>
       <div style={{ width: 80, margin: 5, paddingTop: 10 }}>
        <Toggle size="lg" checkedChildren="OFF" unCheckedChildren="ON" onChange={toggle}/>
       </div>
       </Pane>

       <Pane display="flex"
                      flexDirection={smallRes ? 'column' : 'row'}
                      flexWrap="wrap"
                      alignItems="center"
                      justifyContent="center">
       <div style={{ width: 60, margin: 5 }}>
        <Heading className="text" marginTop={15}>Sort</Heading>
       </div>

       <div style={{ width: 80, margin: 5, paddingTop: 10 }}>
         <Combobox
               width="160px"
               initialSelectedItem={{ label: 'Rank: High to Low' }}
               items={[{ label: 'Rank: High to Low' }, { label: 'Rank: Low to High' }, { label: 'Recently Minted' }, { label: 'Oldest Minted' }]}
               itemToString={item => (item ? item.label : '')}
               onChange={selected => setSortType(selected.label)}
         />
       </div>
       </Pane>

       <Pane display="flex"
        flexDirection={smallRes ? 'column' : 'row'}
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center">
       <div style={{ width: 60, margin: 5 }}>
        <Heading className="text" marginTop={15}>Filters</Heading>
       </div>
       <div style={{ width: 80, margin: 5, paddingTop: 10 }}>
         <ButtonToolbar>
         <IconButton icon={<AngleRightIcon />} onClick={() => handleOpen('left')}>
           Active: {getActiveFiltersCount()}
         </IconButton>
         </ButtonToolbar>
       </div>
       </Pane>

       <Pane display="flex"
        flexDirection={smallRes ? 'column' : 'row'}
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center">
        <div style={{ width: 80, margin: 5 }}>
          <Text fontSize={14} className="text" onClick={clearFilters} textDecoration="underline">(Reset all)</Text>
        </div>
       </Pane>

        <Drawer id="drawer-filter" placement={placement} open={open} onClose={() => setOpen(false)}>
               <Drawer.Header id="drawer-filter">
                 <Drawer.Title id="drawer-filter-title">Filters</Drawer.Title>
                 <Drawer.Actions>
                   <Button onClick={() => clearFilters()}>Reset all</Button>
                 </Drawer.Actions>
               </Drawer.Header>
               <Drawer.Body id="drawer-filter">
               <Heading className="text" marginTop={15}>Back</Heading><InputPicker placeholder='Back' value={backSearchValue} data={backData} style={{ width: 224 }} onChange={setBackSearchValue}/>
               <Heading className="text" marginTop={15}>Background</Heading><InputPicker placeholder='Background' value={backgroundSearchValue} data={backgroundData} style={{ width: 224 }} onChange={setBackgroundSearchValue}/>
               <Heading className="text" marginTop={15}>Clothes</Heading><InputPicker placeholder='Clothes' value={clothesSearchValue} data={clothesData} style={{ width: 224 }} onChange={setClothesSearchValue}/>
               <Heading className="text" marginTop={15}>Earring</Heading><InputPicker placeholder='Earring' value={earringSearchValue} data={earringData} style={{ width: 224 }} onChange={setEarringSearchValue}/>
               <Heading className="text" marginTop={15}>Eye flap</Heading><InputPicker placeholder='Eye flap' value={eyeFlapSearchValue} data={eyeFlapData} style={{ width: 224 }} onChange={setEyeFlapSearchValue}/>
               <Heading className="text" marginTop={15}>Glasses</Heading><InputPicker placeholder='Glasses' value={glassesSearchValue} data={glassesData} style={{ width: 224 }} onChange={setGlassesSearchValue}/>
               <Heading className="text" marginTop={15}>Hat</Heading><InputPicker placeholder='Hat' value={hatSearchValue} data={hatData} style={{ width: 224 }} onChange={setHatSearchValue}/>
               <Heading className="text" marginTop={15}>Head</Heading><InputPicker placeholder='Head' value={headSearchValue} data={headData} style={{ width: 224 }} onChange={setHeadSearchValue}/>
               <Heading className="text" marginTop={15}>Horns</Heading><InputPicker placeholder='Horns' value={hornsSearchValue} data={hornsData} style={{ width: 224 }} onChange={setHornsSearchValue}/>
               <Heading className="text" marginTop={15}>Mouth</Heading><InputPicker placeholder='Mouth' value={mouthSearchValue} data={mouthData} style={{ width: 224 }} onChange={setMouthSearchValue}/>
               <Heading className="text" marginTop={15}>Scars</Heading><InputPicker placeholder='Scars' value={scarsSearchValue} data={scarsData} style={{ width: 224 }} onChange={setScarsSearchValue}/>
               </Drawer.Body>
             </Drawer>

       </Card>
    </Pane>

    {!elitePending && eliteNfts.length > 0 &&
    <Pane marginBottom={10} marginTop={10}>
       <Heading size={700} className="h2">
          Your NFTs staked in MEC Pool ({eliteNfts.length})
       </Heading>
    </Pane>
    }
    <Pane
    display="flex"
    flexWrap="wrap"
    flexDirection={smallRes ? 'column' : 'row'}
    justifyContent="start"
    alignItems="center"
    >
    {elitePending ? (
    <Spinner marginX="auto" marginY="auto" />
    ) : (
    eliteNfts.map((nft: any, index: number) =>
    NFTCollection == nft.collection ? (
    <Card
    width={smallRes ? '100%' : '15%'}
    marginTop={5}
    marginBottom={5}
    marginLeft={smallRes ? '0' : '10px'}
    paddingTop={5}
    paddingBottom={5}
    elevation={1}
    key={index}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Link href={`${explorerAddress}nfts/${nft.identifier}`}>
      <Heading className="text" marginTop={5} marginBottom={5}>
         {nft.name}
      </Heading>
    </Link>
    <Image
       src={nft.media[0].url}
       alt="NFT image"
       width={smallRes ? 200: 150}
       borderRadius={20}
       />
    <Pane>
       <Text fontSize={14} className="text">
          Rank:{' '}
       </Text>
       <Text
          color="gray800"
          className="text"
          fontSize={16}
          textDecoration="underline"
          >
          {ranks[parseInt(nft.name.split("#").pop())]}
       </Text>
    </Pane>
        <Pane marginTop={5} hidden={!displayAttributesChecked}>

               <Heading className="text" marginTop={15}>
                  Attributes
               </Heading>
               </Pane>

               {nft.metadata && nft.metadata.attributes && nft.metadata.attributes.map((attr) => <Pane hidden={!displayAttributesChecked}><Text fontSize={12} className="text">{attr.trait_type}:{' '}</Text>
               <Text fontSize={12} className="text">{attr.value}</Text></Pane>)}
    <Pane paddingTop={10}>
       <Button
          marginTop={5}
          appearance="primary"
          intent={getUnstakeButtonIntent(nft.identifier)}
          fontSize={14}
          paddingTop={10}
          paddingBottom={10}
          onClick={send(unstakeTransaction,nft.identifier)}>
       Unstake
       </Button>
    </Pane>
    </Card>
    ) : null
    )
    )}
    </Pane>

    {!blessedPending && blessedNfts.length > 0 &&
    <Pane marginBottom={10} marginTop={10}>
       <Heading size={700} className="h2">
          Your NFTs staked in Blessed Pool ({blessedNfts.length})
       </Heading>
    </Pane>
    }
    <Pane
    display="flex"
    flexWrap="wrap"
    flexDirection={smallRes ? 'column' : 'row'}
    justifyContent="start"
    alignItems="center"
    >
    {blessedPending ? (
    <Spinner marginX="auto" marginY="auto" />
    ) : (
    blessedNfts.map((nft: any, index: number) =>
    NFTCollection == nft.collection ? (
    <Card
    width={smallRes ? '100%' : '15%'}
    marginTop={5}
    marginBottom={5}
    marginLeft={smallRes ? '0' : '10px'}
    paddingTop={5}
    paddingBottom={5}
    elevation={1}
    key={index}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Link href={`${explorerAddress}nfts/${nft.identifier}`}>
      <Heading className="text" marginTop={5} marginBottom={5}>
         {nft.name}
      </Heading>
    </Link>
    <Image
       src={nft.media[0].url}
       alt="NFT image"
       width={smallRes ? 200: 150}
       borderRadius={20}
       />
    <Pane>
       <Text fontSize={14} className="text">
          Rank:{' '}
       </Text>
       <Text
          color="gray800"
          className="text"
          fontSize={16}
          textDecoration="underline"
          >
          {ranks[parseInt(nft.name.split("#").pop())]}
       </Text>
    </Pane>
        <Pane marginTop={5} hidden={!displayAttributesChecked}>

               <Heading className="text" marginTop={15}>
                  Attributes
               </Heading>
               </Pane>

               {nft.metadata && nft.metadata.attributes && nft.metadata.attributes.map((attr) => <Pane hidden={!displayAttributesChecked}><Text fontSize={12} className="text">{attr.trait_type}:{' '}</Text>
               <Text fontSize={12} className="text">{attr.value}</Text></Pane>)}
    <Pane paddingTop={10}>
       <Button
          marginTop={5}
          appearance="primary"
          intent={getUnstakeButtonIntent(nft.identifier)}
          fontSize={14}
          paddingTop={10}
          paddingBottom={10}
          onClick={send(unstakeTransaction,nft.identifier)}>
       Unstake
       </Button>
    </Pane>
    </Card>
    ) : null
    )
    )}
    </Pane>

    {!damnedPending && damnedNfts.length > 0 &&
    <Pane marginBottom={10} marginTop={10}>
       <Heading size={700} className="h2">
          Your NFTs staked in Damned Pool ({damnedNfts.length})
       </Heading>
    </Pane>
    }
    <Pane
    display="flex"
    flexWrap="wrap"
    flexDirection={smallRes ? 'column' : 'row'}
    justifyContent="start"
    alignItems="center"
    >
    {damnedPending ? (
    <Spinner marginX="auto" marginY="auto" />
    ) : (
    damnedNfts.map((nft: any, index: number) =>
    NFTCollection == nft.collection ? (
    <Card
    width={smallRes ? '100%' : '15%'}
    marginTop={5}
    marginBottom={5}
    marginLeft={smallRes ? '0' : '10px'}
    paddingTop={5}
    paddingBottom={5}
    elevation={1}
    key={index}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Link href={`${explorerAddress}nfts/${nft.identifier}`}>
      <Heading className="text" marginTop={5} marginBottom={5}>
         {nft.name}
      </Heading>
    </Link>
    <Image
       src={nft.media[0].url}
       alt="NFT image"
       width={smallRes ? 200: 150}
       borderRadius={20}
       />
    <Pane>
       <Text fontSize={14} className="text">
          Rank:{' '}
       </Text>
       <Text
          color="gray800"
          className="text"
          fontSize={16}
          textDecoration="underline"
          >
          {ranks[parseInt(nft.name.split("#").pop())]}
       </Text>
    </Pane>
        <Pane marginTop={5} hidden={!displayAttributesChecked}>

               <Heading className="text" marginTop={15}>
                  Attributes
               </Heading>
               </Pane>

               {nft.metadata && nft.metadata.attributes && nft.metadata.attributes.map((attr) => <Pane hidden={!displayAttributesChecked}><Text fontSize={12} className="text">{attr.trait_type}:{' '}</Text>
               <Text fontSize={12} className="text">{attr.value}</Text></Pane>)}
    <Pane paddingTop={10}>
       <Button
          marginTop={5}
          appearance="primary"
          intent={getUnstakeButtonIntent(nft.identifier)}
          fontSize={14}
          paddingTop={10}
          paddingBottom={10}
          onClick={send(unstakeTransaction,nft.identifier)}>
       Unstake
       </Button>
    </Pane>
    </Card>
    ) : null
    )
    )}
    </Pane>

    {!specialPending && specialNfts.length > 0 &&
    <Pane marginBottom={10} marginTop={10}>
       <Heading size={700} className="h2">
          Your NFTs staked in Special Pool ({specialNfts.length})
       </Heading>
    </Pane>
    }
    <Pane
    display="flex"
    flexWrap="wrap"
    flexDirection={smallRes ? 'column' : 'row'}
    justifyContent="start"
    alignItems="center"
    >
    {specialPending ? (
    <Spinner marginX="auto" marginY="auto" />
    ) : (
    specialNfts.map((nft: any, index: number) =>
    NFTCollection == nft.collection ? (
    <Card
    width={smallRes ? '100%' : '15%'}
    marginTop={5}
    marginBottom={5}
    marginLeft={smallRes ? '0' : '10px'}
    paddingTop={5}
    paddingBottom={5}
    elevation={1}
    key={index}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Link href={`${explorerAddress}nfts/${nft.identifier}`}>
      <Heading className="text" marginTop={5} marginBottom={5}>
         {nft.name}
      </Heading>
    </Link>
    <Image
       src={nft.media[0].url}
       alt="NFT image"
       width={smallRes ? 200: 150}
       borderRadius={20}
       />
    <Pane>
       <Text fontSize={14} className="text">
          Rank:{' '}
       </Text>
       <Text
          color="gray800"
          className="text"
          fontSize={16}
          textDecoration="underline"
          >
          {ranks[parseInt(nft.name.split("#").pop())]}
       </Text>
    </Pane>
    <Pane marginTop={5} hidden={!displayAttributesChecked}>

           <Heading className="text" marginTop={15}>
              Attributes
           </Heading>
           </Pane>

           {nft.metadata && nft.metadata.attributes && nft.metadata.attributes.map((attr) => <Pane hidden={!displayAttributesChecked}><Text fontSize={12} className="text">{attr.trait_type}:{' '}</Text>
           <Text fontSize={12} className="text">{attr.value}</Text></Pane>)}

    <Pane paddingTop={5}>
       <Button
          marginTop={5}
          appearance="primary"
          intent={getUnstakeButtonIntent(nft.identifier)}
          fontSize={14}
          paddingTop={10}
          paddingBottom={10}
          onClick={send(unstakeTransaction,nft.identifier)}>
       Unstake
       </Button>
    </Pane>
    </Card>
    ) : null
    )
    )}
    </Pane>
    <Pane marginBottom={10} marginTop={10}>
       <Heading size={700} className="h2">
          Your NFTs staked in Mooiar Pool ({nfts.length})
       </Heading>
    </Pane>
    <Pane
    display="flex"
    flexWrap="wrap"
    flexDirection={smallRes ? 'column' : 'row'}
    justifyContent="start"
    alignItems="center"
    >
    {pending ? (
    <Spinner marginX="auto" marginY="auto" />
    ) : (
    nfts.map((nft: any, index: number) =>
    NFTCollection == nft.collection ? (
    <Card
    width={smallRes ? '100%' : '15%'}
    marginTop={5}
    marginBottom={5}
    marginLeft={smallRes ? '0' : '10px'}
    paddingTop={5}
    paddingBottom={5}
    elevation={1}
    key={index}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Link href={`${explorerAddress}nfts/${nft.identifier}`}>
      <Heading className="text" marginTop={5} marginBottom={5}>
         {nft.name}
      </Heading>
    </Link>
    <Image
       src={nft.media[0].url}
       alt="NFT image"
       width={smallRes ? 200: 150}
       borderRadius={20}
       />
    <Pane>
       <Text fontSize={14} className="text">
          Rank:{' '}
       </Text>
       <Text
          color="gray800"
          className="text"
          fontSize={16}
          textDecoration="underline"
          >
          {ranks[parseInt(nft.name.split("#").pop())]}
       </Text>
    </Pane>
        <Pane marginTop={5} hidden={!displayAttributesChecked}>

               <Heading className="text" marginTop={15}>
                  Attributes
               </Heading>
               </Pane>

               {nft.metadata && nft.metadata.attributes && nft.metadata.attributes.map((attr) => <Pane hidden={!displayAttributesChecked}><Text fontSize={12} className="text">{attr.trait_type}:{' '}</Text>
               <Text fontSize={12} className="text">{attr.value}</Text></Pane>)}
    <Pane paddingTop={isEligibleForSpecialPool(nft.identifier) ? 5 : 10} hidden={!isEligibleForSpecialPool(nft.identifier)}>
       <Button
          marginTop={isEligibleForSpecialPool(nft.identifier) ? 5 : 20}
          intent="success"
          appearance="primary"
          fontSize={14}
          paddingTop={10}
          paddingBottom={10}
          onClick={moveToSpecialPool(moveToSpecialPoolTransaction,nft.identifier)}
          >
       Move to Special Pool
       </Button>
    </Pane>
    <Pane paddingTop={isEligibleForSpecialPool(nft.identifier) ? 5 : 5}>
       <Button
          marginTop={isEligibleForSpecialPool(nft.identifier) ? 5 : 5}
          appearance="primary"
          fontSize={14}
          paddingTop={10}
          paddingBottom={10}
          onClick={send(unstakeTransaction,nft.identifier)}>
       Unstake
       </Button>
    </Pane>
    </Card>
    ) : null
    )
    )}
    {!pending && nfts.length === 0 && !isFilterEnabled() &&
    <Text>There are no Staked NFTs yet.</Text>
    }
    {!pending && nfts.length === 0 && isFilterEnabled() &&
    <Text>There are no Staked NFTs matching your search criteria.</Text>
    }
    </Pane>
    </>
  );
};

export default AccountStakedTab;
