import MainLayout from './MainLayout';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useRef, useState } from 'react';
import { getRemainingNfts, getScValueQuery } from '../apiEndpoints';
import { NFTContract, egldDenominator, denominator } from '../config';
import previewNFT from '../static/media/previewNFT.gif';
import { Pane, Button, Text, Card, Heading } from 'evergreen-ui';
import * as Dapp from '@elrondnetwork/dapp';
import Roadmap from './Dashboard/Roadmap';
import Countdown from 'react-countdown';
import AboutProject from './Dashboard/AboutProject';
import SmartContract from './Dashboard/SmartContract';

const Home: React.FC = () => {
  const numberFormatter = new Intl.NumberFormat();
  const history = useHistory();
  const { account, address, explorerAddress } = Dapp.useContext();
  const [remainingNFT, setRemainingNfts] = useState([]);
  const [pending, setPending] = useState(false);
  const mounted = useRef(true);
  const [mintPaused, setMintPaused] = useState(false);
  const [nftPrice, setNftPrice] = useState(null);
  const [nftEsdtPrice, setNftEsdtPrice] = useState(null);
  const [whitelistedNftPrice, setWhitelistedNftPrice] = useState(null);
  const [whitelistedNftEsdtPrice, setWhitelistedNftEsdtPrice] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setPending(true);

    const fetchMintPaused = async () => {
      const settings = { method: 'POST', body: JSON.stringify({ scAddress: NFTContract, funcName: 'isMintingPaused'})};
      fetch(getScValueQuery(), settings)
        .then(response => response.json())
        .then(json => setMintPaused(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16) === 1));
    };

    const fetchNftPrice = async () => {
      const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTContract, funcName: 'getNftPrice'})};
      fetch(getScValueQuery(), fetchSettings)
        .then(response => response.json())
        .then(json => setNftPrice(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)));
    };

    const fetchWhitelistedNftPrice = async () => {
      const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTContract, funcName: 'getWhitelistedNftPrice'})};
      fetch(getScValueQuery(), fetchSettings)
        .then(response => response.json())
        .then(json => setWhitelistedNftPrice(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)));
    };

    const fetchNftEsdtPrice = async () => {
      const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTContract, funcName: 'getEsdtNftPrice'})};
      fetch(getScValueQuery(), fetchSettings)
        .then(response => response.json())
        .then(json => setNftEsdtPrice(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)));
    };

    const fetchWhitelistedNftEsdtPrice = async () => {
      const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: NFTContract, funcName: 'getWhitelistedEsdtNftPrice'})};
      fetch(getScValueQuery(), fetchSettings)
        .then(response => response.json())
        .then(json => setWhitelistedNftEsdtPrice(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)));
    };

    const fetchRemainingNfts = async () => {
      const response = await fetch(getRemainingNfts(), { signal });
      const data = await response.json();
      if (mounted.current) {
        setRemainingNfts(data);
        setPending(false);
      }
    };
    fetchMintPaused();
    fetchNftPrice();
    fetchWhitelistedNftPrice();
    fetchNftEsdtPrice();
    fetchWhitelistedNftEsdtPrice();
    fetchRemainingNfts();
    return () => {
      mounted.current = false;
    };
  }, [address]);

  const unlock = () => {
    history.push('/unlock');
  };

  const smallRes = useMediaQuery({
    query: '(max-width: 600px)',
  });

  const date = new Date(2022, 1, 13, 14, 0, 0);

  return (
    <MainLayout>
      <Pane>
        <Pane
          display="flex"
          flexDirection={smallRes ? 'column' : 'row'}
          justifyContent="center"
          alignItems="center"
          marginTop={smallRes ? '20' : '94'}
        >
          <img
            src={previewNFT}
            id="preview-nft"
            alt="loading..."
            style={{ margin: 'auto' }}
          />
          <Pane
            display="flex"
            flexDirection={smallRes ? 'column' : 'row'}
            flexWrap="wrap"
            justifyContent="space-around"
            alignItems="center"
          >
            <Card
              width={smallRes ? '100%' : '40%'}
              height={smallRes ? '20%' : '15%'}
              marginTop={smallRes ? 25 : 15}
              marginBottom={smallRes ? 20 : 15}
              paddingTop={smallRes ? 15 : 15}
              paddingBottom={smallRes ? 10 : 15}
              elevation={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              className="card"
            >
              <Heading fontSize={24} className="h2">
                Current Prices:
              </Heading>
              <Text fontSize={15} className="text">
                {!isNaN(whitelistedNftPrice) && whitelistedNftPrice / egldDenominator}{isNaN(whitelistedNftPrice) && 0.4} EGLD / {!isNaN(nftPrice) && nftPrice / egldDenominator}{isNaN(nftPrice) && 0.4} EGLD
              </Text>
              <Text fontSize={15} className="text">
                {!isNaN(whitelistedNftEsdtPrice) && numberFormatter.format(whitelistedNftEsdtPrice / denominator)}{isNaN(whitelistedNftEsdtPrice) && numberFormatter.format(40000)} MOO / {!isNaN(nftEsdtPrice) && numberFormatter.format(nftEsdtPrice / denominator)}{isNaN(nftEsdtPrice) && numberFormatter.format(40000)} MOO
              </Text>
            </Card>
            <Card
              width={smallRes ? '100%' : '40%'}
              height={smallRes ? '20%' : '15%'}
              marginTop={smallRes ? 10 : 15}
              marginBottom={smallRes ? 20 : 15}
              paddingTop={smallRes ? 15 : 15}
              paddingBottom={smallRes ? 15 : 15}
              elevation={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              className="card"
            >
              <Heading fontSize={24} className="h2">
                Total Minted:
              </Heading>
              <Text fontSize={15} className="text">                
              {remainingNFT}/5000
              </Text>
            </Card>
            <Card
              width={smallRes ? '100%' : '90%'}
              height={smallRes ? '20%' : '15%'}
              marginTop={smallRes ? 10 : 15}
              marginBottom={smallRes ? 20 : 15}
              paddingTop={smallRes ? 15 : 25}
              paddingBottom={smallRes ? 15 : 25}
              elevation={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              className="card"
            >
              <Heading size={1600} marginBottom={10} className="h2">
                Mint your own NFT today!
              </Heading>
              <Text textAlign="center" marginTop="10px" className="text">
              MooiarBulls is a collection under MooiarNFT brand. 
              </Text>
              <Text textAlign="center" marginTop="5px" className="text">
              It consists of 5 000 unique and randomly generated 2D Bulls on MultiversX Blockchain &#9889;
              </Text>
              {/* <Text textAlign="center" marginTop="5px" className="text">
                
              </Text> */}
              {/*
              <Countdown
                date={date}
                renderer={props => <div className='countdown'>{props.days} days and {String(props.hours).padStart(2, '0')}:{String(props.minutes).padStart(2, '0')}:{String(props.seconds).padStart(2, '0')}</div>}
              />
              */}
              <Button
                marginTop={20}
                appearance="primary"
                fontSize={14}
                paddingTop={20}
                paddingBottom={20}
                onClick={unlock}
                disabled={mintPaused}
              >
                Mint now
              </Button>
            </Card>
          </Pane>

        </Pane>
      </Pane>
      <SmartContract />
    </MainLayout>
  );
};

export default Home;
