import { Ui } from '@elrondnetwork/dapp-utils';
import {
  Pane,
  Heading,
  Paragraph,
  Link,
  Text,
  Card,
  Spinner,
  Image,
  Button,
  HatIcon
} from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';
import * as Dapp from '@elrondnetwork/dapp';
import { getTokenBalance, getScValueQuery } from '../../apiEndpoints';
import { useEffect, useRef, useState } from 'react';
import { stakingTokenIdentifier, NFTCollection, MooStakeContract, MooTradingContract, denominator } from '../../config';
import InputNumber from 'rsuite/InputNumber';
import InputGroup from 'rsuite/InputGroup';
import Input from 'rsuite/Input';
import useNewTransaction from '../useNewTransaction';
import { RawTransactionType } from '../../helpers/types';
import {Address} from '@elrondnetwork/erdjs';
import { Balance } from '@elrondnetwork/erdjs';
import BigNumber from "bignumber.js";
import mooTokenLogo from '../../static/media/mooTokenLogo.png';

const AccountStakedTab = () => {
  const { account, address, explorerAddress } = Dapp.useContext();

  const [stakingEnabled, setStakingEnabled] = useState(false);

  const [selectedStakingPercentage, setSelectedStakingPercentage] = useState(100);
  const [selectedUnstakingPercentage, setSelectedUnstakingPercentage] = useState(100);

  //text input
  const [stakingValue, setStakingValue] = useState(null);
  const [unstakingValue, setUnstakingValue] = useState(null);
  const [rewardsValue, setRewardsValue] = useState(null);
  //user-specific
  const [tokenBalance, setTokenBalance] = useState(null);
  const [stakedAmount, setStakedAmount] = useState(null);
  const [rewards, setRewards] = useState(null);
  //trading
  const [egldTradingValue, setEgldTradingValue] = useState(null);
  const [mooTradingValue, setMooTradingValue] = useState(null);
  const [maxEgldTradingValue, setMaxEgldTradingValue] = useState(null);
  //selling
  const [mooSellingValue, setMooSellingValue] = useState(null);
  const [egldSellingValue, setEgldSellingValue] = useState(null);
  //total-specific
  const [apr, setApr] = useState(null);
  const [totalStakedAmount, setTotalStakedAmount] = useState(null);
  const [totalStakers, setTotalStakers] = useState(null);

  const [vipLevel,setVipLevel]=useState(null);
  const [remainingSellingMoo,setRemainingSellingMoo]=useState(null);

  const numberFormatter = new Intl.NumberFormat();

  const mounted = useRef(true);
  const smallRes = useMediaQuery({
    query: '(max-width: 600px)',
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

     setMaxEgldTradingValue(new BigNumber(account.balance).dividedBy(new BigNumber(1000000000000000000)).toFixed(2));




     const fetchVipLevel = async () => {
          const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: MooTradingContract, funcName: 'getVipTier', args: [new Address(address).hex()]})};

          fetch(getScValueQuery(), fetchSettings)
            .then(response => response.json())
            .then(json => setVipLevel( parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)  ));
        };

     const fetchRemainingSellingMoo = async () => {
          const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: MooTradingContract, funcName: 'getRemainingSellLimit', args: [new Address(address).hex()]})};

          fetch(getScValueQuery(), fetchSettings)
            .then(response => response.json())
            .then(json => setRemainingSellingMoo( (parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16) / denominator).toFixed(0) ));
        };

    const fetchStakingAllowed = async () => {
          const settings = { method: 'POST', body: JSON.stringify({ scAddress: MooStakeContract, funcName: 'isAllowedStaker', caller: address})};
          fetch(getScValueQuery(), settings)
            .then(response => response.json())
            .then(json => setStakingEnabled(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16) === 1));
        };

    const fetchTokenBalance = async () => {
          fetch(getTokenBalance(address, stakingTokenIdentifier), {signal})
            .then(response => response.json())
            .then(json => setTokenBalance((json[0].balance / denominator).toFixed(0)));
        };

     const fetchTokenBalanceForStakingValue = async () => {
               fetch(getTokenBalance(address, stakingTokenIdentifier), {signal})
                 .then(response => response.json())
                 .then(json => setStakingValue((json[0].balance / denominator).toFixed(0)));
             };

     const fetchStakingRewards = async () => {
          const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: MooStakeContract, funcName: 'getStakingRewards', caller: address})};

          fetch(getScValueQuery(), fetchSettings)
            .then(response => response.json())
            .then(json => setRewards( (parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16) / denominator).toFixed(0) ));
        };

     const fetchStakingRewardsForRewardsValue = async () => {
          const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: MooStakeContract, funcName: 'getStakingRewards', caller: address})};

          fetch(getScValueQuery(), fetchSettings)
            .then(response => response.json())
            .then(json => setRewardsValue(json.data.data.returnData[0].length > 0 ? (parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16) / denominator).toFixed(0)  : 0));
        };

      const fetchStakedAmount = async () => {
          const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: MooStakeContract, funcName: 'getStakedToken', args: [new Address(address).hex()]})};

          fetch(getScValueQuery(), fetchSettings)
            .then(response => response.json())
            .then(json => setStakedAmount( (parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16) / denominator).toFixed(0)  ));
        };

      const fetchStakedAmountForUnstakingValue = async () => {
          const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: MooStakeContract, funcName: 'getStakedToken', args: [new Address(address).hex()]})};

          fetch(getScValueQuery(), fetchSettings)
            .then(response => response.json())
            .then(json => setUnstakingValue( (parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16) / denominator).toFixed(0)  ));
        };

      const fetchApr = async () => {
          const fetchSettings = { method: 'POST', body: JSON.stringify({ scAddress: MooStakeContract, funcName: 'getApr'})};

          fetch(getScValueQuery(), fetchSettings)
            .then(response => response.json())
            .then(json => setApr(parseInt(Buffer.from(json.data.data.returnData[0], 'base64').toString('hex'), 16)));
        };

    if (address) {
      fetchStakingAllowed();
      fetchTokenBalance();
      fetchTokenBalanceForStakingValue();
      fetchStakedAmount();
      fetchStakedAmountForUnstakingValue();
      fetchStakingRewards();
      fetchStakingRewardsForRewardsValue();
      fetchApr();
      fetchVipLevel();
      fetchRemainingSellingMoo();
    }

    return () => {
      mounted.current = false;
    };
  }, [address]);

        const changeStakingValue =
          (percentage) => async (e: React.MouseEvent) => {
            const value = (tokenBalance * percentage) / 100;
            setStakingValue(value.toFixed(0));
            setSelectedStakingPercentage(percentage);
          };



       function getStakingButtonStyle(percentage) {
        if (selectedStakingPercentage === percentage) {
          return {color: "blue"};
        }
        return {color: "white"};
       }

              function getStakingButtonAppearence(percentage) {
               if (selectedStakingPercentage === percentage) {
                 return 'default';
               }
               return 'primary';
              }

        const changeUnstakingValue =
          (percentage) => async (e: React.MouseEvent) => {
            const value = (stakedAmount * percentage) / 100;
            setUnstakingValue(value.toFixed(0));
            setSelectedUnstakingPercentage(percentage);
          };

                        function getUnstakingButtonAppearence(percentage) {
                         if (selectedUnstakingPercentage === percentage) {
                           return 'default';
                         }
                         return 'primary';
                        }


                                        function getUnstakingButtonStyle(percentage) {
                                                if (selectedUnstakingPercentage === percentage) {
                                                  return {color: "blue"};
                                                }
                                                return {color: "white"};
                                               }



     const sendTransaction = Dapp.useSendTransaction();
        const newTransaction = useNewTransaction();
        const stake =
          (transaction: RawTransactionType) => async (e: React.MouseEvent) => {
            const esdtToken = Buffer.from(stakingTokenIdentifier).toString('hex');
            const amount_denominated = (stakingValue - 1) * denominator;
            const amount = amount_denominated.toString(16);
            const amount_hex = amount.length % 2 === 0 ? amount : '0' + amount;
            const smartContractFunction = Buffer.from('stake').toString('hex');
            transaction.data = `ESDTTransfer@${esdtToken}@${amount_hex}@${smartContractFunction}`;
            e.preventDefault();
            sendTransaction({
              transaction: newTransaction(transaction),
              callbackRoute: '/dashboard',
            });
          };

        const unstake =
          (transaction: RawTransactionType) => async (e: React.MouseEvent) => {
            const amount_denominated = (unstakingValue - 1) * denominator;
            const amount = amount_denominated.toString(16);
            const amount_hex = amount.length % 2 === 0 ? amount : '0' + amount;
            transaction.data = `unstake@${amount_hex}`;
            e.preventDefault();
            sendTransaction({
              transaction: newTransaction(transaction),
              callbackRoute: '/dashboard',
            });
          };

 const claim = (transaction: RawTransactionType) => async (e: React.MouseEvent) => {
      e.preventDefault();
      sendTransaction({
        transaction: newTransaction(transaction),
        callbackRoute: '/dashboard',
      });
    };

 const reinvest = (transaction: RawTransactionType) => async (e: React.MouseEvent) => {
      e.preventDefault();
      sendTransaction({
        transaction: newTransaction(transaction),
        callbackRoute: '/dashboard',
      });
    };

 const buy = (transaction: RawTransactionType) => async (e: React.MouseEvent) => {
      transaction.value = String(egldTradingValue);
      e.preventDefault();
      sendTransaction({
        transaction: newTransaction(transaction),
        callbackRoute: '/dashboard',
      });
    };

 const sell = (transaction: RawTransactionType) => async (e: React.MouseEvent) => {
      const esdtToken = Buffer.from(stakingTokenIdentifier).toString('hex');
      const amount_denominated = mooSellingValue * denominator;
      const amount = amount_denominated.toString(16);
      const amount_hex = amount.length % 2 === 0 ? amount : '0' + amount;
      const smartContractFunction = Buffer.from('sell').toString('hex');
      transaction.data = `ESDTTransfer@${esdtToken}@${amount_hex}@${smartContractFunction}`;
      e.preventDefault();
      sendTransaction({
        transaction: newTransaction(transaction),
        callbackRoute: '/dashboard',
      });
    };

    const claimTransaction: RawTransactionType = {
      receiver: MooStakeContract,
      value: '0',
      data: 'claim',
      gasLimit: 70000000,
    };

    const reinvestTransaction: RawTransactionType = {
      receiver: MooStakeContract,
      value: '0',
      data: 'reinvest',
      gasLimit: 70000000,
    };

  const stakeTransaction: RawTransactionType = {
      receiver: MooStakeContract,
      value: '0',
      data: 'ESDTTransfer',
      gasLimit: 70000000,
    };

  const unstakeTransaction: RawTransactionType = {
      receiver: MooStakeContract,
      value: '0',
      gasLimit: 70000000,
    };

  const buyTransaction: RawTransactionType = {
      receiver: MooTradingContract,
      value: '0',
      data: 'buy',
      gasLimit: 7000000,
    };

  const sellTransaction: RawTransactionType = {
      receiver: MooTradingContract,
      value: '0',
      data: 'sell',
      gasLimit: 7000000,
    };

  function isStakeButtonDisabled() {
    return !stakingEnabled || isNaN(tokenBalance) || tokenBalance <= 1;
  }

  function getVipTier() {
    if (vipLevel == 1) {
      return "Gold";
    } else if (vipLevel == 2) {
      return "Silver";
    } else if (vipLevel == 3) {
      return "Bronze";
    }

    return "N/A";
  }

  function isUnstakeButtonDisabled() {
    return isNaN(stakedAmount) || stakedAmount <= 1;
  }

  function isRewardButtonDisabled() {
    return isNaN(rewards) || rewards <= 1;
  }

  return (
    <>
    <Pane marginBottom={10} marginTop={10}>
       <Heading size={700} className="h2">
          Your portfolio
       </Heading>
    </Pane>
    <Pane display="flex" flexDirection={smallRes ? 'column' : 'row'}>
    <Card
    width={smallRes ? '100%' : '50%'}
    marginRight={smallRes ? 0 : 10}
    marginBottom={25}
    padding={30}
    elevation={1}
    className="card"
    >
    <Paragraph data-testid="accountTab-address">
       <Text fontSize={18} className="text">
          Your wallet address:
       </Text>
       <br/>
       <Link href={`${explorerAddress}accounts/${address}`}>
       <Text wordWrap="break-word" className="text">
          {address}
       </Text>
       </Link>
    </Paragraph>
    <br/>
    <Paragraph data-testid="accountTab-address">
       <Text fontSize={18} className="text" textDecoration="underline">
          Balance
       </Text>
       <br/>
       <Text wordWrap="break-word" className="text">
          &nbsp;&nbsp;&nbsp;
          <Ui.Denominate value={account.balance} erdLabel="$EGLD" />
       </Text>
       <br/>
       <Text wordWrap="break-word" className="text">
          &nbsp;&nbsp;&nbsp;{numberFormatter.format(tokenBalance)} $MOO
       </Text>
    </Paragraph>
    <br/>
    <Paragraph data-testid="accountTab-address">
       <Text fontSize={18} className="text">
         VIP level:
       </Text>
       <Text fontSize={16} className="text">
          &nbsp;{getVipTier()}
       </Text>
    </Paragraph>
    <br/>
    <Paragraph data-testid="accountTab-address">
       <Text fontSize={18} className="text">
          Remaining selling limit:
       </Text>
        <Text fontSize={16} className="text">
          &nbsp;{!isNaN(remainingSellingMoo) && numberFormatter.format(remainingSellingMoo)}{isNaN(remainingSellingMoo) && 0} $MOO
       </Text>
    </Paragraph>
    </Card>
    <Card
    width={smallRes ? '100%' : '20%'}
    marginRight={smallRes ? 0 : 10}
    marginBottom={25}
    padding={30}
    elevation={1}
    className="card"
    >
    <Pane alignItems="center" justifyContent="center" display="flex" paddingTop={10}>
       <Text fontSize={18} className="text">
          1 $MOO
       </Text>
    </Pane>
    <Pane alignItems="center" justifyContent="center" display="flex" paddingTop={10}>
           <Image
                  src={mooTokenLogo}
                  alt="$MOO Token Logo"
                  width={75}
                  borderRadius={20}
                  />
     </Pane>
    <Pane alignItems="center" justifyContent="center" display="flex" paddingTop={10}>
       <Text fontSize={18} className="text">
          =
       </Text>
    </Pane>
    <Pane alignItems="center" justifyContent="center" display="flex" paddingTop={10}>
       <Text fontSize={18} className="text">
          0.00001 $EGLD
       </Text>
    </Pane>
    </Card>
    <Card
    width={smallRes ? '100%' : '30%'}
    marginRight={smallRes ? 0 : 10}
    marginBottom={25}
    padding={30}
    elevation={1}
    className="card"
    >
    <Pane alignItems="center" justifyContent="center" display="flex" paddingTop={10}>
       <Text fontSize={18} className="text">
          Total value locked:
       </Text>
    </Pane>
    <Pane alignItems="center" justifyContent="center" display="flex" paddingTop={10}>
       <Text fontSize={20} className="text">
       {!isNaN(stakedAmount) && numberFormatter.format(stakedAmount - 1)}{isNaN(stakedAmount) && 0}
       </Text>
    </Pane>
    <Pane alignItems="center" justifyContent="center" display="flex" paddingTop={10}>
       <Text fontSize={20} className="text">
          $MOO
       </Text>
    </Pane>
    <Pane alignItems="center" justifyContent="center" display="flex" paddingTop={10}>
       <Text fontSize={14} className="text">
          ({!isNaN(stakedAmount) && numberFormatter.format(stakedAmount / 100000)}{isNaN(stakedAmount) && 0} $EGLD)
       </Text>
    </Pane>
    </Card>
    </Pane>
     <Pane>
           <Heading size={700} marginBottom={10} className="h2" textDecoration="underline">
              Buy your $MOO
           </Heading>
    </Pane>
    <Pane display="flex" flexDirection={smallRes ? 'column' : 'row'}>
    <Card
    width={smallRes ? '100%' : '33.3%'}
    marginTop={5}
    marginBottom={10}
    paddingTop={5}
    paddingBottom={20}
    elevation={1}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Heading className="text" marginTop={20}>
       From
    </Heading>
    <Pane marginTop={25} width={'55%'}>
           <InputGroup>
           <InputGroup.Addon id="input-number-moo-staking">$EGLD</InputGroup.Addon>
                 <InputNumber id="input-number-moo-staking"
                 defaultValue={1}
                 value={egldTradingValue ? egldTradingValue: 1}
                 onChange={setEgldTradingValue}
                 step={0.1}
                 min={0.01}
                 max={maxEgldTradingValue}
                 />
                 </InputGroup>
        </Pane>

        </Card>
        <Card
            width={smallRes ? '100%' : '33.3%'}
            marginTop={5}
            marginBottom={10}
            paddingTop={5}
            paddingBottom={20}
            elevation={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className="card"
            >
    <Heading className="text" marginTop={20}>
       To
    </Heading>
            <Pane marginTop={25} width={'55%'}>
                   <InputGroup>
                   <InputGroup.Addon id="input-number-moo-staking">$MOO</InputGroup.Addon>
                         <Input id="input-number-moo-staking"
                         value={egldTradingValue ? numberFormatter.format(egldTradingValue*100000) : numberFormatter.format(100000)}
                         onChange={setMooTradingValue}
                         step={1000}
                         min={1000}
                         />
                         </InputGroup>
                </Pane>

                </Card>

<Card
            width={smallRes ? '100%' : '33.3%'}
            marginTop={5}
            marginBottom={10}
            paddingTop={5}
            paddingBottom={20}
            elevation={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className="card"
            >

            <Pane marginTop={55} width={'55%'}>
                   <Button marginTop={10}
                      width={'100%'}
                      appearance="primary"
                      fontSize={14}
                      paddingTop={20}
                      paddingBottom={20}
                      onClick={buy(buyTransaction)}>Buy</Button>
                </Pane>

                </Card>
        </Pane>


<Pane>
           <Heading size={700} marginBottom={10} className="h2" textDecoration="underline">
              Sell your $MOO
           </Heading>
    </Pane>

    <Pane display="flex" flexDirection={smallRes ? 'column' : 'row'}>
    <Card
    width={smallRes ? '100%' : '33.3%'}
    marginTop={5}
    marginBottom={10}
    paddingTop={5}
    paddingBottom={20}
    elevation={1}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Heading className="text" marginTop={20}>
       From
    </Heading>

    <Pane marginTop={25} width={'55%'}>
           <InputGroup>
           <InputGroup.Addon id="input-number-moo-staking">$MOO</InputGroup.Addon>
                 <InputNumber id="input-number-moo-staking"
                 defaultValue={1}
                 value={mooSellingValue ? mooSellingValue : 10000}
                 onChange={setMooSellingValue}
                 step={1000}
                 min={100}
                 max={remainingSellingMoo}
                 />
                 </InputGroup>
        </Pane>

        </Card>
        <Card
            width={smallRes ? '100%' : '33.3%'}
            marginTop={5}
            marginBottom={10}
            paddingTop={5}
            paddingBottom={20}
            elevation={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className="card"
            >
    <Heading className="text" marginTop={20}>
       To
    </Heading>
            <Pane marginTop={25} width={'55%'}>
                   <InputGroup>
                   <InputGroup.Addon id="input-number-moo-staking">$EGLD</InputGroup.Addon>
                         <Input id="input-number-moo-staking"
                         value={mooSellingValue ? numberFormatter.format(mooSellingValue/100000) : 0.1}
                         onChange={setEgldSellingValue}
                         step={0.1}
                         min={0.01}
                         />
                         </InputGroup>
                </Pane>

                </Card>

<Card
            width={smallRes ? '100%' : '33.3%'}
            marginTop={5}
            marginBottom={10}
            paddingTop={5}
            paddingBottom={20}
            elevation={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className="card"
            >

            <Pane marginTop={55} width={'55%'}>
                   <Button marginTop={10}
                      width={'100%'}
                      appearance="primary"
                      fontSize={14}
                      paddingTop={20}
                      paddingBottom={20}
                      onClick={sell(sellTransaction)}>Sell</Button>
                </Pane>

                </Card>
        </Pane>


    <Pane marginTop={10}>
       <Heading size={700} marginBottom={10} className="h2" textDecoration="underline">
          Stake your $MOO
       </Heading>

    <Heading className="text" marginTop={15}>
      (min. 3,000 $MOO)
    </Heading>

    <Heading className="text" marginTop={15}>
       APR: 0%
    </Heading>
    </Pane>
    <Pane display="flex" flexDirection={smallRes ? 'column' : 'row'}>
    <Card
    width={smallRes ? '100%' : '33.3%'}
    marginTop={5}
    marginBottom={10}
    paddingTop={5}
    paddingBottom={20}
    elevation={1}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Heading className="text" marginTop={50}>
       Available
    </Heading>
    <Pane marginTop={55} width={'55%'}>
       <InputGroup>
       <InputGroup.Addon id="input-number-moo-staking">$MOO</InputGroup.Addon>
             <Input id="input-number-moo-staking" disabled
             defaultValue={stakingValue ? numberFormatter.format(stakingValue - 1) : 0}
             value={stakingValue ? numberFormatter.format(stakingValue - 1) : 0}
             onChange={setStakingValue}
             />
             </InputGroup>
    </Pane>
    <Pane width={'55%'}>
       <Button marginTop={10}
          width={'100%'}
          appearance="primary"
          fontSize={14}
          paddingTop={20}
          paddingBottom={20}
          onClick={stake(stakeTransaction)}
          disabled={isStakeButtonDisabled() || stakingValue < 3000}>Stake</Button>
    </Pane>
    <Pane width={'55%'}>
       <Button marginTop={10}
          width={'24%'}
          appearance={getStakingButtonAppearence(25)}
          fontSize={14}
          paddingTop={20}
          paddingBottom={20}
          marginRight={2}
          style={getStakingButtonStyle(25)}
          onClick={changeStakingValue(25)}
          disabled={isStakeButtonDisabled()}>25%</Button>
       <Button marginTop={10}
          width={'24%'}
          appearance={getStakingButtonAppearence(50)}
          fontSize={14}
          paddingTop={20}
          paddingBottom={20}
          marginRight={2}
          style={getStakingButtonStyle(50)}
          onClick={changeStakingValue(50)}
          disabled={isStakeButtonDisabled()}>50%</Button>
       <Button marginTop={10}
          width={'24%'}
          appearance={getStakingButtonAppearence(75)}
          fontSize={14}
          paddingTop={20}
          paddingBottom={20}
          marginRight={2}
          style={getStakingButtonStyle(75)}
          onClick={changeStakingValue(75)}
          disabled={isStakeButtonDisabled()}>75%</Button>
       <Button marginTop={10}
          width={'24%'}
          appearance={getStakingButtonAppearence(100)}
          fontSize={14}
          paddingTop={20}
          paddingBottom={20}
          style={getStakingButtonStyle(100)}
          onClick={changeStakingValue(100)}
          disabled={isStakeButtonDisabled()}>100%</Button>
    </Pane>
    </Card>

  <Card
    width={smallRes ? '100%' : '33.3%'}
    marginTop={5}
    marginBottom={10}
    paddingTop={5}
    paddingBottom={20}
    elevation={1}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Heading className="text" marginTop={50}>
       Staking
    </Heading>
    <Pane marginTop={55} width={'55%'}>
       <InputGroup>
       <InputGroup.Addon id="input-number-moo-staking">$MOO</InputGroup.Addon>
             <Input id="input-number-moo-staking" disabled
             defaultValue={!isNaN(unstakingValue) ? numberFormatter.format(unstakingValue - 1) : 0}
             value={!isNaN(unstakingValue) ? numberFormatter.format(unstakingValue - 1) : 0}
             onChange={setUnstakingValue}
             />
             </InputGroup>
    </Pane>
    <Pane width={'55%'}>
       <Button marginTop={10}
          width={'100%'}
          appearance="primary"
          fontSize={14}
          paddingTop={20}
          paddingBottom={20}
          onClick={unstake(unstakeTransaction)}
          disabled={isUnstakeButtonDisabled()}>Unstake</Button>
    </Pane>
    <Pane width={'55%'}>
       <Button marginTop={10}
          width={'24%'}
          appearance={getUnstakingButtonAppearence(25)}
          fontSize={14}
          paddingTop={20}
          paddingBottom={20}
          marginRight={2}
          style={getUnstakingButtonStyle(25)}
          onClick={changeUnstakingValue(25)}
          disabled={isUnstakeButtonDisabled()}>25%</Button>
       <Button marginTop={10}
          width={'24%'}
          appearance={getUnstakingButtonAppearence(50)}
          fontSize={14}
          paddingTop={20}
          paddingBottom={20}
          marginRight={2}
          style={getUnstakingButtonStyle(50)}
          onClick={changeUnstakingValue(50)}
          disabled={isUnstakeButtonDisabled()}>50%</Button>
       <Button marginTop={10}
          width={'24%'}
          appearance={getUnstakingButtonAppearence(75)}
          fontSize={14}
          paddingTop={20}
          paddingBottom={20}
          marginRight={2}
          style={getUnstakingButtonStyle(75)}
          onClick={changeUnstakingValue(75)}
          disabled={isUnstakeButtonDisabled()}>75%</Button>
       <Button marginTop={10}
          width={'24%'}
          appearance={getUnstakingButtonAppearence(100)}
          fontSize={14}
          paddingTop={20}
          paddingBottom={20}
          style={getUnstakingButtonStyle(100)}
          onClick={changeUnstakingValue(100)}
          disabled={isUnstakeButtonDisabled()}>100%</Button>
    </Pane>
     </Card>

<Card
    width={smallRes ? '100%' : '33.3%'}
    marginTop={5}
    marginBottom={10}
    paddingTop={5}
    paddingBottom={20}
    elevation={1}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className="card"
    >
    <Heading className="text" marginTop={50}>
       Rewards
    </Heading>
    <Pane marginTop={55} width={'55%'}>
      <InputGroup>
      <InputGroup.Addon id="input-number-moo-staking">$MOO</InputGroup.Addon>
             <Input id="input-number-moo-staking" disabled
           defaultValue={rewardsValue ? numberFormatter.format(rewardsValue) : 0}
          value={rewardsValue ? numberFormatter.format(rewardsValue) : 0}/>
             </InputGroup>
    </Pane>
    <Pane width={'55%'}>
       <Button marginTop={10}
          width={'100%'}
          appearance="primary"
          fontSize={14}
          paddingTop={20}
          paddingBottom={20}
          onClick={claim(claimTransaction)}
          disabled={isRewardButtonDisabled()}>Harvest all</Button>
    </Pane>
    <Pane width={'55%'}>
           <Button marginTop={10}
              width={'100%'}
              appearance="primary"
              fontSize={14}
              paddingTop={20}
              paddingBottom={20}
              onClick={reinvest(reinvestTransaction)}
              disabled={isRewardButtonDisabled()}>Reinvest all</Button>
        </Pane>
    </Card>


    </Pane>
    </>
  );
};

export default AccountStakedTab;
