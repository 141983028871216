import { Pane, Heading, Text } from 'evergreen-ui';
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <>
      {
        <Pane
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text className="MadeWith">
            Made with ❤️ by MooiarNFT Team
          </Text>
        </Pane>
      }
    </>
  );
};

export default Footer;
