import { network, NFTCollection, VIPNFTCollection } from './config';

export const getTransactionByHash = (txHash: string) =>
  `${network.apiAddress}/transactions/${txHash}`;

export const getTransactions = (senderAddress: string, from = 0, size = 5) =>
  `${network.apiAddress}/transactions?sender=${senderAddress}&receiver=${senderAddress}&condition=should&from=${from}&size=${size}`;

export const getNfts = (walletAddress: string) =>
  `${network.apiAddress}/accounts/${walletAddress}/nfts?size=400&collections=MOO-bd3a9f`;

export const getVIPNfts = (walletAddress: string) =>
  `${network.apiAddress}/accounts/${walletAddress}/nfts?size=50&collections=MOOVIP-3051f0`;

export const getStakedNfts = (identifiers: string) =>
  `${network.apiAddress}/collections/${NFTCollection}/nfts?size=400&identifiers=MOO-bd3a9f-${identifiers}`;

export const getScValueQuery = () =>
  `${network.gatewayAddress}/vm-values/query`;

export const getNft = (number: string) =>
  `${network.apiAddress}/collections/${NFTCollection}/nfts?name=%23${number}`;

export const getTokenBalance = (walletAddress: string, tokenIdentifier: string) =>
  `${network.apiAddress}/accounts/${walletAddress}/tokens?identifier=${tokenIdentifier}`;

export const getRemainingNfts = () =>
  `${network.apiAddress}/nfts/count?collection=${NFTCollection}`;

export const getRemainingVIPNfts = () =>
  `${network.apiAddress}/nfts/count?collection=${VIPNFTCollection}`;

export const getLotteryTickets = (address: string) =>
  `${network.apiAddress}/accounts/${address}/tokens/MOOL-dfcc41?extract=balance`;

export const getMintTransactionsCount = (address: string) =>
  `${network.apiAddress}/transactions/count?size=100&sender=${address}&receiver=erd1qqqqqqqqqqqqqpgqul8643sr78422edx9kxpfdk5qpqw744wlg2sghanht&status=success&function=mint&after=1671082019`;
